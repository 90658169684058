@media(min-width: 1024px) {
    .logos-container {
      background-image: url("../../public/images/honduautos-background.png");
      background-color: rgb(239, 237, 237);
      width: 60%;
      border-radius: 1vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-left: 5%;
      margin-bottom: 2%;
      overflow-y: auto;
      max-height: 80vh; /* Ajusta esto según tus necesidades */
    }

    .logo-count{
      color: black;
    }
    
    .logo-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1%;
      width: 100%;
      flex-wrap: nowrap;
    }
    
    .logo-reference {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%; /* Asegura que ocupe el alto completo */
      padding: 10px;
      margin: 5px;
      background-color: white; /* Color de fondo de la tarjeta */
      border-radius: 8px; /* Bordes redondeados */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para el efecto de tarjeta */
    }
    
    .square-logo-container,
    .large-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
    }
  
    .square-logo-container {
      width: 150px;
      height: 150px;
    }
    
    .large-logo-container {
      width: 300px;
      height: 150px;
    }
  
    .square-logo,
    .large-logo {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }

  @media(min-width: 768px) and (max-width: 1024px){
    .logos-container {
      background-image: url("../../public/images/honduautos-background.png");
      background-color: rgb(239, 237, 237);
      width: 90%;
      border-radius: 1vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 2%;
      overflow-y: auto;
      max-height: 50vh; /* Ajusta esto según tus necesidades */
    }
    
    .logo-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1%;
      width: 100%;
      flex-wrap: nowrap;
    }
    
    .logo-reference {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%; /* Asegura que ocupe el alto completo */
      margin: 5px;
      background-color: white; /* Color de fondo de la tarjeta */
      border-radius: 8px; /* Bordes redondeados */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para el efecto de tarjeta */
    }
    
    .square-logo-container,
    .large-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
    }
  
    .square-logo-container {
      width: 50px;
      height: 50px;
    }

    .logo-count{
      color: black;
      font-size: x-small;
    }
    
    .large-logo-container {
      width: 100px;
      height: 50px;
    }
  
    .square-logo,
    .large-logo {
      max-width: 80%;
      max-height: 50%;
      object-fit: contain;
    }
  }
  

  @media(max-width: 768px) {
    .logos-container {
      background-image: url("../../public/images/honduautos-background.png");
      background-color: rgb(239, 237, 237);
      width: 90%;
      border-radius: 1vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 2%;
      overflow-y: auto;
      max-height: 50vh; /* Ajusta esto según tus necesidades */
    }
    
    .logo-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1%;
      width: 100%;
      flex-wrap: nowrap;
    }
    
    .logo-reference {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%; /* Asegura que ocupe el alto completo */
      margin: 5px;
      background-color: white; /* Color de fondo de la tarjeta */
      border-radius: 8px; /* Bordes redondeados */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para el efecto de tarjeta */
    }
    
    .square-logo-container,
    .large-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
    }
  
    .square-logo-container {
      width: 50px;
      height: 50px;
    }

    .logo-count{
      color: black;
      font-size: x-small;
    }
    
    .large-logo-container {
      width: 100px;
      height: 50px;
    }
  
    .square-logo,
    .large-logo {
      max-width: 80%;
      max-height: 50%;
      object-fit: contain;
    }
  }