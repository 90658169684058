

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --bgColorLightbtn: #BACBCF;
}



p{
    padding: 0.5vh 0;
}

ul{
    list-style-type: none;
    padding-left: 0;
}

a{
    text-decoration: none;
    color: white;
}

@media(min-width:900px){
    /*Button style*/
#drop-menu-movil{
    border-color: transparent;
    background-color: #E8E7E7;
    color: black;
    font-size: small;
    margin-right: 0.5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    border-radius: 1vh;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    z-index: 999;
}

.drop-text{
    color: black;
}

#arro   {
    transition: transform 0.3s ease;
    width: 13%;
    margin-left: 5%;
    background-color: transparent;
}

.arrow-up {
    transform: rotate(180deg);
  }

.menu-drop{
    width: 100%;
    background-color: #E8E7E7;
    height: 200%;
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    padding:0;
    border-color: 1px solid black;
    border-radius: 0vh 0vh 1vh 1vh;
    z-index: 1;
    color: black;
}

.buttonNoMargin{
    margin: 0;
}

.list-ref{
    color: var(--bgColorDarkbtn);
    font-weight: 600;
    margin: 0.1%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#drop-menu.clicked{
    border-radius: 1vh 1vh 0vh 0vh;
}

.a-color{
    color: var(--bgColorDarkbtn);
    background-color: var(--bgColorLightbtn);
    background-size: cover;
    height: 100%;
}

.line hr{
    padding: 0;
    margin: 0;
    border-color: var(--bgColorDarkbtn);
}

.line{
    padding-bottom: 5%;
}

#second{
    padding: 5% 0;
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*Button style*/

    .whole-drop{
        margin: 0 0.5vh;
    }

    button#drop-menu{
        padding: 3%;
        color: black;
    }

    p.drop-text{
        color: black;
        font-weight: 800;
        font-size: small;
    }
    
    #drop-menu-movil{
        border-color: transparent;
        background-color: #E8E7E7;
        color: black;
        font-size: xx-small;
        margin-right: 0.5%;
        width: 100%;
        border-radius: 5vh;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #arro   {
        transition: transform 0.3s ease;
        width: 13%;
        margin-left: 5%;
        background-color: transparent;
    }
    
    .arrow-up {
        transform: rotate(180deg);
      }
    
    .menu-drop-m{
        width: 100%;
        background-color: #E8E7E7;
        display: grid;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        padding:0;
        border-color: 1px solid black;
        border-radius: 1vh;
        z-index: 1;
        margin-top: 5%;
    }
    
    .buttonNoMargin{
        margin: 0;
    }
    
    .list-ref-m{
        color: black;
        font-weight: 600;
        margin: 0.1%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        background-color: transparent;
    }
    
    #drop-menu.clicked{
        border-radius: 1vh 1vh 0vh 0vh;
    }
    
    .a-color-m{
        color: black;
        background-color: transparent;
        background-size: cover;
        height: 100%;
        font-size: xx-small;
    }
    
    .line-m hr{
        padding: 0;
        margin: 0;
        border-color: black;
    }
    
    .line-m{
        padding-bottom: 5%;
        border-color: black;
    }
    
    #second{
        padding: 5% 0;
    }   
}

@media(max-width:600px){
    /*Button style*/

    .whole-drop{
        margin: 0 0.5vh;
    }

    button#drop-menu{
        padding: 3%;
        color: black;
    }

    p.drop-text{
        color: black;
        font-weight: 800;
        font-size: small;
    }
    
    #drop-menu-movil{
        border-color: transparent;
        background-color: #E8E7E7;
        color: black;
        font-size: xx-small;
        margin-right: 0.5%;
        width: 100%;
        border-radius: 5vh;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #arro   {
        transition: transform 0.3s ease;
        width: 13%;
        margin-left: 5%;
        background-color: transparent;
    }
    
    .arrow-up {
        transform: rotate(180deg);
      }
    
    .menu-drop-m{
        width: 100%;
        background-color: #E8E7E7;
        display: grid;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        padding:0;
        border-color: 1px solid black;
        border-radius: 1vh;
        z-index: 1;
        margin-top: 5%;
    }
    
    .buttonNoMargin{
        margin: 0;
    }
    
    .list-ref-m{
        color: black;
        font-weight: 600;
        margin: 0.1%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        background-color: transparent;
    }
    
    #drop-menu.clicked{
        border-radius: 1vh 1vh 0vh 0vh;
    }
    
    .a-color-m{
        color: black;
        background-color: transparent;
        background-size: cover;
        height: 100%;
        font-size: xx-small;
    }
    
    .line-m hr{
        padding: 0;
        margin: 0;
        border-color: black;
    }
    
    .line-m{
        padding-bottom: 5%;
        border-color: black;
    }
    
    #second{
        padding: 5% 0;
    }   
}