

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #EDECEC;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

ul{
    margin: 0;
    padding: 0;
    list-style-type: disc;
}

@media(min-width: 900px){
    /*Sell*/
.honduautos-backk{
    background-image: url('../../public/images/honduautos-background.png');
    padding-bottom: 5%;
}

.sell-title{
    font-size: xx-large;
    font-weight: 500;
    padding-top: 10%;
}   

.form-container-sell{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin: 0 15%;
    padding: 4%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1%;
}

.lote{
    display: flex;
    margin-left: 4%;
}

.lote input{
    padding: 1% 0;
}

.fire-button{
    width: 100%;
    padding: 0 1%;
}

.fire{
    margin: 0;
    padding: 0;
    width: 4%;
    border-radius: 1vh;
}

.sell-input{
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin-right: 1%;
}

.widget{
    margin: 1% 0 1% 4%;
}

.grid-sell{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    margin: 0;
}

.grid-sell-movil{
    display: none;
}

.grid-sell-last{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 0;
    margin: 0 3.4%;
}

.columns{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.columns input{
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
}

.columns-2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.columns-2 input{
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 1.5% 0;
}

.add-car{
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-large;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 5%;
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*Sell*/
.honduautos-backk{
    background-image: url('../../public/images/honduautos-background.png');
    padding-bottom: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    padding-top: 40%;
}

.sell-title{
    font-size: xx-large;
    font-weight: 500;
    padding-top: 10%;
}   

.form-container-sell{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin: 0 15%;
    padding: 4%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1%;
}

.lote{
    display: flex;
    margin-left: 4%;
}

.lote input{
    padding: 1% 0;
}

.fire-button{
    width: 100%;
    padding: 0 1%;
}

.fire{
    margin: 0;
    padding: 0;
    width: 4%;
    border-radius: 1vh;
}

.sell-input{
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin-right: 1%;
}

.widget{
    margin: 1% 0 1% 4%;
}

.grid-sell{
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: none;
}

.grid-sell-movil{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grid-sell-last{
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
}

input{
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 2% 1%;
    padding: 1% 0;
}

.columns-2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.columns-2 input{
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 2% 0;
    width: 100%;
}

.add-car{
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-large;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 5%;
}
}

@media(max-width: 600px){
    /*Sell*/
.honduautos-backk{
    background-image: url('../../public/images/honduautos-background.png');
    padding-bottom: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 40%;
    justify-self: center;
    flex-direction: column;
}

.sell-title{
    padding-top: 10%;
    font-size: xx-large;
    font-weight: 500;
}   

.form-container-sell{
    display: flex;
    background-color: #FFFFFF;
    flex-direction: column;
    margin: 0 15%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 4%;
    margin-top: 1%;
}

.lote{
    margin-left: 4%;
    display: flex;
}

.lote input{
    padding: 1% 0;
}

.fire-button{
    width: 100%;
    padding: 0 1%;
}

.fire{
    margin: 0;
    border-radius: 1vh;
    padding: 0;
    width: 4%;
}

.sell-input{
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin-right: 1%;
}

.widget{
    margin: 1% 0 1% 4%;
}

.grid-sell{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    display: none;
    gap: 0;
    padding: 0;
    margin: 0;
}

.grid-sell-movil{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grid-sell-last{
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
}

input{
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 2% 1%;
    padding: 1% 0;
}

.columns-2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.columns-2 input{
    height: 100%;
    margin: 5px 3px;
    padding: 2% 0;
    width: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
   
}

.add-car{
    border-color: transparent;
    background-color: var(--bgColorRed);
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    color: white;
    font-size: x-large;
    margin-top: 5%;
}
}