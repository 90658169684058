@media(min-width: 900px){
.widget-upload-btn{
    background-color: #D9D9D9;
    padding: 4% 1% 4% 1%;
    border-radius: 8px;
    width: 14vw;
    display: flex;
    justify-content: flex-start;
}
}

@media(min-width: 600px) and (max-width: 900px){
    .widget-upload-btn{
        background-color: #D9D9D9;
        padding: 4% 1% 4% 1%;
        border-radius: 8px;
        width: 40vw;
        display: flex;
        justify-content: center;
    }
    }

@media(max-width: 600px){
    .widget-upload-btn{
        background-color: #D9D9D9;
        padding: 4% 1% 4% 1%;
        border-radius: 8px;
        width: 40vw;
        display: flex;
        justify-content: center;
    }
    }