

:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
  --bgColorAdmin: #aab4b7;
}



p {
  padding: 0.5vh 0;
  margin: 0;
}

@media (min-width: 900px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .success-message {
    position: fixed;
    top: 20%;
    right: 35%;
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 15px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .main-container-admin-ec {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 110vh;
    border-radius: 10px;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-ec {
    background-color: #F7F7F7;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .title-input {
    width: 80%;
    margin: 2% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    padding: 0.5% 0 0.5% 1%;
    border-radius: 10px;
  }

  .title-input::placeholder {
    font-weight: 600;
  }

  .options-edit {
    display: flex;
    background-color: #d9d9d9;
    width: 80%;
    padding: 2% 0;
    border-radius: 10px;
    justify-content: space-between;
  }

  .element-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .edit-icon-a {
    width: 40%;
  }

  .edit-icon-p {
    width: 30%;
  }

  .edit-icon-av {
    width: 35%;
  }

  .edit-icon-b {
    width: 30%;
  }

  .edit-icon-c {
    width: 20%;
  }

  .title-option {
    font-size: 0.7em;
    font-weight: 600;
  }

  .save-btn-e {
    margin-top: 10%;
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: 600;
    font-size: 1.3em;
    width: 20%;
    padding: 0.5% 0;
    border-radius: 10px;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .success-message {
    position: fixed;
    top: 20%;
    right: 35%;
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 15px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .main-container-admin-ec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 50%;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-ec {
    background-color: white;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .title-input {
    width: 80%;
    margin: 2% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    padding: 0.5% 0 0.5% 1%;
    border-radius: 10px;
  }

  .title-input::placeholder {
    font-weight: 600;
  }

  .options-edit {
    display: flex;
    background-color: #d9d9d9;
    width: 80%;
    padding: 2% 0;
    border-radius: 10px;
    justify-content: space-between;
  }

  .element-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .edit-icon-a {
    width: 40%;
  }

  .edit-icon-p {
    width: 30%;
  }

  .edit-icon-av {
    width: 35%;
  }

  .edit-icon-b {
    width: 30%;
  }

  .edit-icon-c {
    width: 20%;
  }

  .title-option {
    font-size: 0.7em;
    font-weight: 600;
    text-align: center;
  }

  .save-btn-e {
    margin-top: 30%;
    margin-bottom: 5%;
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: 600;
    font-size: 1.3em;
    width: 40%;
    padding: 0.5% 0;
    border-radius: 10px;
  }
}

@media (max-width: 600px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .success-message {
    position: fixed;
    top: 20%;
    right: 35%;
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 15px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .main-container-admin-ec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 50%;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-ec {
    background-color: white;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .title-input {
    width: 80%;
    margin: 2% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    padding: 0.5% 0 0.5% 1%;
    border-radius: 10px;
  }

  .title-input::placeholder {
    font-weight: 600;
  }

  .options-edit {
    display: flex;
    background-color: #d9d9d9;
    width: 80%;
    padding: 2% 0;
    border-radius: 10px;
    justify-content: space-between;
  }

  .element-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .edit-icon-a {
    width: 40%;
  }

  .edit-icon-p {
    width: 30%;
  }

  .edit-icon-av {
    width: 35%;
  }

  .edit-icon-b {
    width: 30%;
  }

  .edit-icon-c {
    width: 20%;
  }

  .title-option {
    font-size: 0.7em;
    font-weight: 600;
    text-align: center;
  }

  .save-btn-e {
    margin-top: 30%;
    margin-bottom: 5%;
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: 600;
    font-size: 1.3em;
    width: 40%;
    padding: 0.5% 0;
    border-radius: 10px;
  }
}
