@media(min-width: 900px){
    .content-body{
        padding: 10% 10% 3% 10%;
    }

    .content-body-p{
        padding: 10% 10% 3% 10%;
    }

    .content-body-a{
        padding: 10% 10% 3% 10%;
    }
    
    .content-body-c{
        padding: 0 10% 0 10%;
    }    
}

@media(min-width: 600px) and (max-width: 900px){
    .content-body{
        padding: 10% 10% 3% 10%;
    }
    
    .content-body-c{
        padding: 0 10% 0 10%;
    }    

    .content-body-p{
        padding: 50% 10% 0 10%;
    }  
    
    .content-body-a{
        padding: 50% 10% 0 10%;
    }  
}

@media(max-width: 600px){
    .content-body{
        padding: 10% 10% 3% 10%;
    }
    
    .content-body-c{
        padding: 0 10% 0 10%;
    }    

    .content-body-p{
        padding: 50% 10% 0 10%;
    }  
    
    .content-body-a{
        padding: 50% 10% 0 10%;
    }  
}