    :root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
    --bgColorAdmin: #AAB4B7;
}

p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    .baner-container{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .img-uploaded{
        width: 100%;
    }

    .preview-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        padding: 1% 0;
    }

    .widget-ba{
        margin-top: 5%;
        display: flex;
        width: 60%;
        height: 10%;
    }

    .stored-image-img{
        width: 100%;
        margin-right: 1%;
    }

    .stored-images-container{
        width: 80%;
        height: 60%;
        overflow-y: auto;
    }

    .del-btn{
        height: 20%;
    }

    .image-ttl{
        width: 25%;
        text-align: center;
    }

    .preview-btn{
        background-color: var(--bgColorRed);
        color: white;
    }

    .img-preview{
        width: 100%;
    }

    .stored-image{
        display: flex;
        height: 25%;
        height: 50%;
    }

    .widget-ban{
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
    }

    .baner-info{
        width: 80%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .baner-lbl{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3%;
    }

    .baner-esp{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1%;
        width: 80%;
    }

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .success-message {
        position: fixed;
        top: 20%;
        right: 50%;
        background-color: black; 
        color: white;
        font-weight: bold;
        padding: 15px;
        z-index: 1000;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .main-container-admin{
        background-color: var(--bgColorAdmin);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
    }

    .column-admin{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info-c{
        background-color: #F7F7F7;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
    }

    .preview-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .preview-content {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        max-width: 80%;
        max-height: 80%;
        overflow-y: auto;
      }
      
      .close-preview {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #ff0000;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
      }
      
      .preview-image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .preview-image img {
        max-width: 100%;
        max-height: 100%;
      }

    .date-delete{
        margin-left: 1%;
    }

    .main-admin-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .title-input-b{
        width: 80%;
        margin: 2% 0;
        border-color: transparent;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .title-input::placeholder{
        font-weight: 600;
    }

    .edit-icon-a{
        width: 40%;
    }

    .edit-icon-p{
        width: 30%;
    }

    .edit-icon-av{
        width: 35%;
    }

    .edit-icon-b{
        width: 30%;
    }

    .edit-icon-c{
        width: 20%;
    }

    .title-option{
        font-size: 0.7em;
        font-weight: 600;
    }

    .save-btn-b{
        border-color: transparent;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        font-size: 1em;
        width: 20%;
        padding: 0.5% 0;
        border-radius: 10px;
    }
}

@media(min-width: 600px) and (max-width: 900px){
    .baner-container{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .img-preview{
        width: 100%;
    }

    .img-uploaded{
        width: 100%;
    }

    .preview-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        padding: 1% 0;
    }

    .widget-ba{
        margin-top: 5%;
        display: flex;
        width: 60%;
        height: 10%;
    }

    .stored-image-img{
        width: 100%;
        margin-right: 1%;
    }

    .stored-images-container{
        width: 80%;
        height: 60%;
        overflow-y: auto;
    }

    .del-btn{
        height: 20%;
    }

    .image-ttl{
        width: 25%;
        text-align: center;
    }

    .stored-image{
        display: flex;
        height: 25%;
        height: 50%;
    }

    .widget-ban{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
    }

    .baner-info{
        width: 80%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .baner-lbl{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3%;
    }

    .baner-esp{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1%;
        width: 80%;
    }

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .success-message {
        position: fixed;
        top: 20%;
        right: 50%;
        background-color: black; 
        color: white;
        font-weight: bold;
        padding: 15px;
        z-index: 1000;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .main-container-admin{
        background-color: var(--bgColorAdmin);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
    }

    .column-admin{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info-c{
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
    }

    .preview-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .preview-content {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        max-width: 80%;
        max-height: 80%;
        overflow-y: auto;
      }
      
      .close-preview {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #ff0000;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
      }
      
      .preview-image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .preview-image img {
        max-width: 100%;
        max-height: 100%;
      }

    .date-delete{
        margin-left: 1%;
    }

    .main-admin-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .title-input-b{
        width: 80%;
        margin: 2% 0;
        border-color: transparent;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .title-input::placeholder{
        font-weight: 600;
    }

    .edit-icon-a{
        width: 40%;
    }

    .edit-icon-p{
        width: 30%;
    }

    .edit-icon-av{
        width: 35%;
    }

    .edit-icon-b{
        width: 30%;
    }

    .edit-icon-c{
        width: 20%;
    }

    .title-option{
        font-size: 0.7em;
        font-weight: 600;
    }

    .save-btn-b{
        border-color: transparent;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        font-size: 1em;
        width: 40%;
        padding: 0.5% 0;
        border-radius: 10px;
    }
}

@media(max-width:600px){
    .baner-container{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .img-preview{
        width: 100%;
    }

    .img-uploaded{
        width: 100%;
    }

    .preview-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        padding: 1% 0;
    }

    .widget-ba{
        margin-top: 5%;
        display: flex;
        width: 60%;
        height: 10%;
    }

    .stored-image-img{
        width: 100%;
        margin-right: 1%;
    }

    .stored-images-container{
        width: 80%;
        height: 60%;
        overflow-y: auto;
    }

    .del-btn{
        height: 20%;
    }

    .image-ttl{
        width: 25%;
        text-align: center;
    }

    .stored-image{
        display: flex;
        height: 25%;
        height: 50%;
    }

    .widget-ban{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
    }

    .baner-info{
        width: 80%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .baner-lbl{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3%;
    }

    .baner-esp{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1%;
        width: 80%;
    }

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .success-message {
        position: fixed;
        top: 20%;
        right: 50%;
        background-color: black; 
        color: white;
        font-weight: bold;
        padding: 15px;
        z-index: 1000;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .main-container-admin{
        background-color: var(--bgColorAdmin);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
    }

    .column-admin{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info-c{
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
    }

    .preview-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .preview-content {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        max-width: 80%;
        max-height: 80%;
        overflow-y: auto;
      }
      
      .close-preview {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #ff0000;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
      }
      
      .preview-image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .preview-image img {
        max-width: 100%;
        max-height: 100%;
      }

    .date-delete{
        margin-left: 1%;
    }

    .main-admin-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .title-input-b{
        width: 80%;
        margin: 2% 0;
        border-color: transparent;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .title-input::placeholder{
        font-weight: 600;
    }

    .edit-icon-a{
        width: 40%;
    }

    .edit-icon-p{
        width: 30%;
    }

    .edit-icon-av{
        width: 35%;
    }

    .edit-icon-b{
        width: 30%;
    }

    .edit-icon-c{
        width: 20%;
    }

    .title-option{
        font-size: 0.7em;
        font-weight: 600;
    }

    .save-btn-b{
        border-color: transparent;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        font-size: 1em;
        width: 40%;
        padding: 0.5% 0;
        border-radius: 10px;
    }
}