

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){

    .notificationinfo{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .main-container-notification{
        background-color: #F1F1F1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin-left: 5%;
        cursor: pointer;
    }

    .notification-img{
        width: 15%;
    }

    .main-title-notification{
        font-weight: bold;
        margin-top: 1%;
    }

    .notification-info-overview{
        font-size: 0.7em;
    }
}

@media(min-width: 600px) and (max-width: 900px){
    .main-container-notification{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
    }

    .notification-img{
        width: 15%;
    }

    .main-title-notification{
        font-weight: bold;
        margin-top: 1%;
    }

    .notification-info-overview{
        font-size: 0.7em;
    }
}

@media(max-width:600px){
    .main-container-notification{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
    }

    .notification-img{
        width: 15%;
    }

    .main-title-notification{
        font-weight: bold;
        margin-top: 1%;
    }

    .notification-info-overview{
        font-size: 0.7em;
    }

    .notificationinfo{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}