

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding:0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

@media(min-width: 600px) and (max-width: 900px){
    .movil-container{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #383838;
        flex-direction: column;
    }

    .red{
        color: var(--bgColorRed);
    }

    .logo-footer{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p{
        font-weight: bold;
    }

    .white{
        color: #FFFFFF;
    }

    .logos-footer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        margin-top: 2%;
    }

    .log{
        height: 10vh;
        margin: 0 1%;
    }

    .links-f-m{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
        margin-top: 2%;
    }

    .column-m{
        border-left: #DB3A34 solid;
    }

    .column-m a{
        font-weight: bold;
        font-size: x-small;
        width: 100%; 
        padding-left:3vw;
        padding-top: 1vh;
    }

    .contact{
        font-size: x-small;
        font-weight: 500;
        padding-top: 5%;
        color: #FFFFFF;
    }

    .contact-2{
        font-size: xx-small;
        font-weight: 500;
        padding-top: 5%;
        color: #FFFFFF;
    }
}

@media(max-width:600px){
    .movil-container{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #383838;
        flex-direction: column;
    }

    .red{
        color: var(--bgColorRed);
    }

    .logo-footer{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p{
        font-weight: bold;
    }

    .white{
        color: #FFFFFF;
    }

    .logos-footer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        margin-top: 2%;
    }

    .log{
        height: 2.5vh;
        margin: 0 1%;
    }

    .links-m{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
        margin-top: 2%;
    }

    .column{
        border-left: #DB3A34 solid;
    }

    .column a{
        font-weight: bold;
        font-size: x-small;
        width: 100%; 
        padding-left:3vw;
        padding-top: 1vh;
    }

    .contact{
        font-size: x-small;
        font-weight: 500;
        padding-top: 5%;
        color: #FFFFFF;
    }

    .contact-2{
        font-size: xx-small;
        font-weight: 500;
        padding-top: 5%;
        color: #FFFFFF;
    }
}