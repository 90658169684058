

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
}



p{
    padding: 0.5vh 0;
}

@media(min-width:900px){
    .contact-info-m{
        display: none;
    }
    /*Contact part*/

.honduautos-back-contact{
    background-image: url('../../public/images/honduautos-background.png');
    height: 150vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20%;
}

.input-gray{
    background-color: var(--bgColorDarkGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 2%;
    margin-top: 7%;
    border-radius: 1vh;
}

.contactenos{
    font-size: xx-large;
    font-weight: 500;
    text-decoration: underline;
}

.contact-info{
    font-size: small;
    text-align: justify;
}

input.contact-form{
    background-color: #F7F7F7;
    color: black;
    padding: 0;
    margin: 2%;
    width: 100%;
    padding: 2% 0;
    border-color: transparent;
    border-radius: 0.75vh;
}

.contact-form-comentario{
    background-color: #F7F7F7;
    color: black;
    padding: 0;
    margin: 2%;
    width: 100%;
    padding: 10% 0;
    overflow: hidden;
    border-color: transparent;
    border-radius: 0.75vh;
}

input::placeholder{
    color: #a3a2a2;
    padding-left: 2%;
}

.button-send{
    color: white;
    font-weight: 500;
    border-color: transparent;
    background-color: var(--bgColorRed);
    width: 35%;
    padding: 2% 0;
    border-radius: 1vh;
    margin-top: 5%;
    font-size: medium;
}

.contact-card{
    background-color: var(--bgColorDarkGrey);
    margin: 5% 0;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    border-radius: 1vh;
}

.contact-title{
    font-size: 3vh;
    font-weight: 700;
}

.contact-card-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1%;
}

.icon-contact-card{
    width: 10%;
    height: 90%;
    margin-right: 2%;
}

.icon-contact-card-email{
    width: 10%;
    height: 80%;
    margin-right: 2%;
}

.colum-card{
    display: flex;
    flex-direction: column;
    font-size: small;
    justify-content: center;
}

.row-card{
    display: flex;
    flex-direction: row;
}

.icons-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-logo{
    width: 60%;
    margin: 10%;
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*Contact part*/
    .web-footer{
        display: none;
    }

    .contact-info{
        display: none;
    }

.honduautos-back-contact{
    background-image: url('../../public/images/honduautos-background.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20%;
}

.input-gray{
    background-color: var(--bgColorDarkGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4% 2%;
    margin-top: 35%;
    border-radius: 1vh;
    width: 80%;
}

.contactenos{
    font-size: xx-large;
    font-weight: 500;
    text-decoration: underline;
}

.contact-info-m{
    font-size: small;
    text-align: justify;
    padding: 0 5%;
}

input.contact-form{
    background-color: #F7F7F7;
    color: black;
    margin: 2%;
    width: 90%;
    padding: 2% 0;
    border-color: transparent;
    border-radius: 0.75vh;
}

.contact-form-comentario{
    background-color: #F7F7F7;
    color: black;
    padding: 0;
    margin: 2%;
    width: 90%;
    padding: 10% 0;
    overflow: hidden;
    border-color: transparent;
    border-radius: 0.75vh;
}

input::placeholder{
    color: #a3a2a2;
    padding-left: 2%;
}

.button-send{
    color: white;
    font-weight: 500;
    border-color: transparent;
    background-color: var(--bgColorRed);
    width: 35%;
    padding: 2% 0;
    border-radius: 1vh;
    margin-top: 5%;
    font-size: medium;
}

.contact-card{
    background-color: var(--bgColorDarkGrey);
    margin: 5% 0;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    border-radius: 1vh;
    width: 80%;
}

.contact-title{
    font-size: 3vh;
    font-weight: 700;
}

.contact-card-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1%;
}

.contact-card-card p{
    font-size: x-small;
    margin-left: 5%;
}

.icon-contact-card{
    width: 10%;
    height: 90%;
}

.icon-contact-card-email{
    width: 10%;
    height: 80%;
}

.colum-card{
    display: flex;
    flex-direction: column;
    font-size: small;
}

.row-card{
    display: flex;
    flex-direction: row;
}

.icons-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-logo{
    width: 60%;
    margin: 10%;
}
}

@media(max-width: 600px){
    /*Contact part*/
    .web-footer{
        display: none;
    }

    .contact-info{
        display: none;
    }

.honduautos-back-contact{
    background-image: url('../../public/images/honduautos-background.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20%;
}

.input-gray{
    background-color: var(--bgColorDarkGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4% 2%;
    margin-top: 35%;
    border-radius: 1vh;
    width: 80%;
}

.contactenos{
    font-size: xx-large;
    font-weight: 500;
    text-decoration: underline;
}

.contact-info-m{
    font-size: small;
    text-align: justify;
    padding: 0 5%;
}

input.contact-form{
    background-color: #F7F7F7;
    color: black;
    margin: 2%;
    width: 90%;
    padding: 2% 0;
    border-color: transparent;
    border-radius: 0.75vh;
}

.contact-form-comentario{
    background-color: #F7F7F7;
    color: black;
    padding: 0;
    margin: 2%;
    width: 90%;
    padding: 10% 0;
    overflow: hidden;
    border-color: transparent;
    border-radius: 0.75vh;
}

input::placeholder{
    color: #a3a2a2;
    padding-left: 2%;
}

.button-send{
    color: white;
    font-weight: 500;
    border-color: transparent;
    background-color: var(--bgColorRed);
    width: 35%;
    padding: 2% 0;
    border-radius: 1vh;
    margin-top: 5%;
    font-size: medium;
}

.contact-card{
    background-color: var(--bgColorDarkGrey);
    margin: 5% 0;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    border-radius: 1vh;
    width: 80%;
}

.contact-title{
    font-size: 3vh;
    font-weight: 700;
}

.contact-card-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1%;
}

.contact-card-card p{
    font-size: x-small;
    margin-left: 5%;
}

.icon-contact-card{
    width: 10%;
    height: 90%;
}

.icon-contact-card-email{
    width: 10%;
    height: 80%;
}

.colum-card{
    display: flex;
    flex-direction: column;
    font-size: small;
}

.row-card{
    display: flex;
    flex-direction: row;
}

.icons-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-logo{
    width: 60%;
    margin: 10%;
}
}
