:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
    --bgColorAdmin: #AAB4B7;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .main-container-admin-u{
        background-color: var(--bgColorAdmin);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
    }

    .searchbar-container{
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .search-bar-mod-allusers {
        position: relative;
        width: 60%;
        margin-right: 1%;
        margin-left: 10%;
    }

    .red-button {
        background-color: #DB3A34; /* Red background color */
        color: #fff; /* White text */
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
    }

    .column-admin{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info-all{
        background-color: #F7F7F7;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .main-admin-title-all{
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
        margin-top: 3%;
    }

    .overview-container{
        width: 90%;
    }

    .overview-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2% 0;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .column-titles{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .column-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: large;
        font-weight: 500;
    }
}


@media(min-width: 600px) and (max-width: 900px){
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .main-container-admin-u{
        width: 100%;
        display: none;
        display: flex;
        height: 100%;
        padding-top: 15%;
    }

    .column-admin{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .main-admin-title-all{
        font-size: x-large;
        font-weight: 500;
        margin-bottom: 3%;
        margin-top: 5%;
    }

    .overview-container{
        width: 90%;
    }

    .overview-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2% 0;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .column-titles{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .column-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: large;
        font-weight: 500;
    }
}

@media(max-width:600px){
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:1% ;
    }

    .main-container-admin-u{
        width: 100%;
        display: none;
        display: flex;
        height: 100%;
        padding-top: 15%;
    }

    .column-admin{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .admin-name{
        color: white;
        font-weight: 500;
        font-size: 1.5em;
        margin-top: 10%;
        padding: 0 5%;
    }

    .admin-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #949A9C;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .main-admin-title-all{
        font-size: x-large;
        font-weight: 500;
        margin-bottom: 3%;
        margin-top: 5%;
    }

    .overview-container{
        width: 90%;
    }

    .overview-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2% 0;
        background-color: #D9D9D9;
        padding: 1% 0;
        border-radius: 10px;
    }

    .column-titles{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .column-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: large;
        font-weight: 500;
    }
}