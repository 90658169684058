.div-home {
  padding-top: 7.5%;
  margin-bottom: 3%;
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-home-style {
  color: white;
  background-color: black;
  padding: 0.5% 2%;
  font-size: medium;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  z-index: 10; /* Asegura que los botones estén por encima de otros elementos */
  width: 11vw;
  height: 5vh;
}

#first-btn {
  border-radius: 1vh 0 0 1vh;
}

#last-btn {
  border-radius: 0 1vh 1vh 0;
}

#middle-btn {
  border-left: white 1px solid;
  border-right: white 1px solid;
}

.btn-home-style.active,
.btn-home-style:hover {
  background-color: white;
  color: black;
  transform: scale(1.05);
  height: 8vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100; /* Asegura que el hover esté por encima */
}

.dropdown-container {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.dropdown-item {
  position: relative;
}

.dropdown-menu-horizontal {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 1000; /* Asegura que el dropdown esté por encima de otros elementos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  visibility: hidden; /* Inicialmente oculto */
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu-horizontal::-webkit-scrollbar{
  width: 6px;
}

.dropdown-menu-horizontal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.dropdown-menu-horizontal::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
  border: 2px solid transparent; 
}

.dropdown-menu-horizontal::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.dropdown-item:hover .dropdown-menu-horizontal,
.dropdown-item .btn-home-style:focus + .dropdown-menu-horizontal,
.dropdown-item .btn-home-style.active + .dropdown-menu-horizontal {
  visibility: visible;
  opacity: 1;
}

.list-item-hd {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item-hd li {
  padding: 5px 10px;
  cursor: pointer;
}

.list-item-hd li:hover {
  color: black;
  background-color: white;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
