

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
    --bgColorAdmin: #AAB4B7;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    /* src/components/Modal.css */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
  }

  .poup-main {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-height: 500px;
  }
  
  .display-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .display-none {
    display: none;
  }
  
}

@media(min-width: 600px) and (max-width: 900px){
  /* src/components/Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
}

.poup-main {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-height: 500px;
}

.display-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.display-none {
  display: none;
}

}

@media(max-width:600px){
  /* src/components/Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
}

.poup-main {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-height: 500px;
}

.display-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.display-none {
  display: none;
}

}