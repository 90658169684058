

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

@media(min-width:900px){

    .car-updated-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    .car-updated-h{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 80%;
    }

    .car-updated{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 30%;
    }

    .info-part-s{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-items: start;
        color: #425860;
        margin-top: 50%;
    }

    .info-part-s p{
        line-height: 0.7;
    }

    .lote-s{
        margin-left: 4%;
    }

    .saldo{
        margin-left: 4%;
        font-weight: bold;
        font-size: 1.1em;
    }
    
    .price-home-s{
        margin-left: 4%;
        font-weight: bolder;
        font-size: 1.5em;
        margin: 1% 4%;
    }
    
    .car-model-s{
        margin: 0;
        margin-left: 4%;
        font-weight: bolder;
    }
    
    .offer-image{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-car-container{
        display: flex;
        align-self: flex-start;
        justify-content: center;
        width: 8vw;
    }
}

@media(min-width: 600px) and (max-width: 900px){

    .car-updated-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    .car-updated-h{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 80%;
    }

    .car-updated{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 50vw;
    }

    .info-part-h{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-items: start;
        color: #425860;
        margin-top: 20%;
        font-size: xx-small;
        width: 50vw;
    }
    
    .price-home{
        margin-left: 4%;
    }
    
    .car-model-h{
        margin: 0;
        width: 30vw;
        margin-left: 4%;
    }
    
    .offer-image{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-car-container{
        display: flex;
        align-self: flex-start;
        justify-content: center;
        width: 15vw;
    }
}

@media(max-width: 600px){

    .car-updated-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    .car-updated-h{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 80%;
    }

    .car-updated{
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 50vw;
    }

    .info-part-s{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-items: start;
        color: #425860;
        margin-top: 20%;
        font-size: xx-small;
        width: 50vw;
    }
    
    .lote-s{
        margin-left: 4%;
    }

    .saldo{
        margin-left: 4%;
        font-weight: bold;
        font-size: 1.1em;
        color: var(--bgColorDarkbtn);
    }
    
    .price-home-s{
        margin-left: 4%;
        font-weight: bolder;
        font-size: 1.5em;
        margin: 1% 4%;
    }
    
    .car-model-s{
        margin: 0;
        margin-left: 4%;
        font-weight: bolder;
    }
    
    .offer-image{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-car-container{
        display: flex;
        align-self: flex-start;
        justify-content: center;
        width: 15vw;
    }
}