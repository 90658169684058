

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
}



p{
    padding: 0.5vh 0;
}



/*Register part*/
input::placeholder{
    color: #FFFFFF;
    padding-right: 15%;
}

input.email-input-reg{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 75%;
    padding: 2% 0;
    border-radius: 1vh;
    border-color: transparent;
}

input.password-input-reg{
    background-color: transparent;
    color: white;
    padding: 0;
    margin: 2%;
    width: 94%;
    border-radius: 1vh;
    border-color: transparent;
}

.password-input-container{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 75%;
    border-radius: 1vh;
    border-color: transparent;
}

input.name-input{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 75%;
    padding: 2% 0;
    border-radius: 1vh;
    border-color: transparent;
}

/* Register.css */

.phone-input-reg {
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 50%;
    padding: 0;
    border-radius: 1vh;
    border-color: transparent;
  }
  
  .phone-input-reg .form-control {
    background-color: transparent;
    border: none;
    color: white;
  }
  

input.city-input{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 75%;
    padding: 2% 0;
    border-radius: 1vh;
    border-color: transparent;
}


input#register-buton{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 3% 0;
    padding: 2% 4%;
    font-weight: 500;
    border-radius: 1vh;
}

.sesion-link{
    text-decoration: none;
    color: var(--bgColorRed);
    font-size: smaller;
    display: inline;
    vertical-align: middle;
}

.email-input-reg::placeholder{
    color: white;
    font-weight: 500;
}

.password-input-reg::placeholder{
    color: white;
    font-weight: 500;
}

.name-input::placeholder{
    color: white;
    font-weight: 500;
}

.city-input::placeholder{
    color: white;
    font-weight: 500;
}