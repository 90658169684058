

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
}



p{
    padding: 0.5vh 0;
}

@media(min-width:900px){
    /*IMAGE BACKGROUND*/
.honduautos-back{
    background-image: url('../../public/images/honduautos-background.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-l{
    display: flex;
    align-items: center;
    justify-content: center;
}

/*login-part*/
.login-info{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 30%; 
    margin: auto; 
    padding: 20px; 
    width: auto;
    height: 90%;
}

img.logo-login{
    width: 70%;
    margin: 3% 0;
}

.email-container{
    width: 80%;
}

.email-input{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 95%;
    padding: 2% 0;
    border-radius: 1vh;
    border-color: transparent;
}

.password-input-container-login{
    position: relative;
    width: 80%;
}

.password-input{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0;
    margin: 2%;
    width: 95%;
    padding: 2% 0;
    border-radius: 1vh;
    border-color: transparent;
    flex: 1;    
}

.eye-icon{
    color: white;
}

.toggle-password-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px; /* Ajusta según sea necesario */
    color: white; /* Color del icono */
  }

.pass-recover{
    font-size: smaller;
    margin-right: 20%;

}

.button-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#login-buton{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 2% 4%;
    font-weight: 500;
    border-radius: 1vh;
    margin-top: 10%;
}

.member{
    margin-top: 15%;
    font-size: smaller;
    padding: 0;
    display: inline;
    vertical-align: middle;
}

.register-link{
    text-decoration: none;
    color: var(--bgColorRed);
    font-size: smaller;
    display: inline;
    vertical-align: middle;
}

.recover-link{
    text-decoration: none;
    color: black;
    font-size: smaller;
    display: inline;
    display: flex;
    align-self: flex-start;
    margin-left: 13%;
}


.email-input::placeholder{
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.password-input::placeholder{
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*IMAGE BACKGROUND*/
.honduautos-back{
    background-image: url('../../public/images/honduautos-background.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-l{
    display: flex;
    align-items: center;
    justify-content: center;
}

/*login-part*/
.login-info{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 0;
    width: 80%;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 1vh;
    height: 50%;
}

img.logo-login{
    width: 70%;
    margin: 3% 0;
}


.password-input-container{
    width: 80%;
}


.email-container{
    width: 80%;
}

.email-input{
    background-color: var(--bgColorRed) !important;
    color: white;
    padding: 1vh 0;
    width: 100%;
    padding: 3% 0;
    border-radius: 1vh;
    border-color: transparent;
    padding-left: 2%;
    height: fit-content;
}
.password-input{
    background-color: var(--bgColorRed);
    color: white;
    padding: 3% 0;
    width: 100%;
    border-radius: 1vh;
    border-color: transparent;
    padding-left: 2%;
    height: fit-content;
}

.toggle-password-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px; 
    color: white; 
  }

.pass-recover{
    font-size: smaller;
    margin-right: 20%;

}

.button-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

#login-buton{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 2% 4%;
    font-weight: 500;
    border-radius: 1vh;
}

.member{
    margin-top: 15%;
    font-size: smaller;
    padding: 0;
    display: inline;
    vertical-align: middle;
}

.register-link{
    text-decoration: none;
    color: var(--bgColorRed);
    font-size: smaller;
    display: inline;
    vertical-align: middle;
}

.recover-link{
    text-decoration: none;
    color: black;
    font-size: smaller;
    display: inline;
    display: flex;
    align-self: flex-start;
    margin-left: 13%;
}

.email-input::placeholder{
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.password-input::placeholder{
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
}
}

@media(max-width:600px){
    /*IMAGE BACKGROUND*/
.honduautos-back{
    background-image: url('../../public/images/honduautos-background.png');
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
}

/*login-part*/
.login-info{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 1vh;
    height: 70%;
    flex-direction: column;
    padding: 10% 0;
}

.logo-login{
    width: 70%;
    margin: 5% 0;
}

.login-l{
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-input-container{
    position: relative;
    width: 80%;
}


.email-container{
    width: 80%;
}

.email-input{
    background-color: var(--bgColorRed) !important;
    color: white;
    padding: 1vh 0;
    width: 100%;
    border-color: transparent;
    padding-left: 2%;
    height: fit-content;
    padding: 3% 0;
    border-radius: 1vh;
}

.password-input{
    background-color: var(--bgColorRed);
    color: white;
    padding-left: 2%;
    height: fit-content;
    border-radius: 1vh;
    padding: 3% 0;
    width: 100%;
    border-color: transparent;
}

.toggle-password-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px; 
    color: white; 
  }

.pass-recover{
    font-size: smaller;
    margin-right: 20%;

}

.button-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

#login-buton{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 2% 4%;
    font-weight: 500;
    border-radius: 1vh;
}

.member{
    margin-top: 15%;
    font-size: smaller;
    padding: 0;
    display: inline;
    vertical-align: middle;
}

.register-link{
    text-decoration: none;
    color: var(--bgColorRed);
    font-size: smaller;
    display: inline;
    vertical-align: middle;
}

.recover-link{
    text-decoration: none;
    color: black;
    font-size: smaller;
    display: inline;
    display: flex;
    align-self: flex-start;
    margin-left: 13%;
}

.email-input::placeholder{
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.password-input::placeholder{
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.password-input-container-login{
    position: relative;
    width: 80%;
}

}