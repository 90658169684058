

:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
}



p {
  padding: 0.5vh 0;
  margin: 0;
}

img {
  margin: 0;
  padding: 0;
}

button {
  border-color: transparent;
}

h1 {
  margin: 0;
  padding: 0;
}

li {
  font-size: medium;
  font-weight: normal;
}

@media (min-width: 900px) {
  /*infoCar container*/
  .infoCar-container {
    width: 100vw;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    justify-content: center;
  }

  .car-title-info{
    margin-left: 5%;
  }

  .normal-set{
    margin-bottom: 10%;
    margin-left: 15%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .column-set{
    margin-bottom: 10%;
    margin-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .images-set-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-image{
    width: 100%;
  }

  .images-info{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 650px;
    width: 100%;
    margin-bottom: 5%;
  }

  .images-info-2{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 650px;
    margin-bottom: 5%;
    margin-left: 10%;
  }

  .columset-img{
    width: 100%;
  }

  .change-set{
    position: absolute;
    top: 10;
    margin-top: 5%;
    left: 10;
    margin-left: 10%;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    padding: 0.5% 1%;
    border-radius: 5px;
  }

  .img360{
    width: 20%;
    margin-left: 5%;
  }

  .view360{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10;
    margin-top: 5%;
    left: 10;
    margin-left: 40%;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }

  .columns-car-info{
    margin-top: 20%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E8E7E7;
    width: 80%;
    padding: 2%; 
    border-radius: 15px;
    font-weight: 600;
  }

  .columns-info{
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    width: 80%;
  }


  .column-element-info{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .precio-info{
    font-size: x-large;
  }

  .precio-data{
    font-size: x-large;
    color: var(--bgColorRed);
  }

  .request-information{
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 1%;
    color: white;
    background-color: var(--bgColorRed);
    border-radius: 10px;
    font-weight: bold;
    margin-top: 2%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  /*infoCar container*/
  .infoCar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.column-set{
  width: 100%;
}

  .main-image{
    width: 100%;
  }

  .change-set{
    position: absolute;
    top: 10;
    margin-top: 15%;
    left: 10;
    margin-left: 1%;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    padding: 0.5% 1%;
    border-radius: 5px;
  }

  .columns-car-info{
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    background-color: #E8E7E7;
    width: 90%;
    padding: 2%; 
    border-radius: 15px;
    font-weight: 600;
  }

  .images-info{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 5%;
  }
  
  .columns-info{
    margin-top: 50%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .column-element-info{
    display: flex;
    justify-content: space-between;
  }

  .precio-info{
    font-size: x-large;
  }

  .precio-data{
    font-size: x-large;
    color: var(--bgColorRed);
  }
}

@media (max-width: 600px) {
  /*infoCar container*/
  .infoCar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.column-set{
  width: 100%;
}

  .main-image{
    width: 100%;
  }

  .change-set{
    position: absolute;
    top: 10;
    margin-top: 15%;
    left: 10;
    margin-left: 1%;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    padding: 0.5% 1%;
    border-radius: 5px;
  }

  .columns-car-info{
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #E8E7E7;
    width: 90%;
    padding: 2%; 
    border-radius: 15px;
    font-weight: 600;
  }

  .images-info{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 5%;
  }

  .view-word{
    display: none;
  }

  .request-information{
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 1%;
    color: white;
    background-color: var(--bgColorRed);
    border-radius: 10px;
    font-weight: bold;
    margin-top: 2%;
  }

  .view360{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10;
    margin-top: 14%;
    left: 10;
    margin-left: 75%;
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    border-radius: 5px;
    width: 10%;
  }

  .img360{
    width: 100%;
  }

  .images-info-2{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 5%;
  }
  
  .columns-info{
    margin-top: 50%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .column-element-info{
    display: flex;
    justify-content: space-between;
  }

  .precio-info{
    font-size: x-large;
  }

  .precio-data{
    font-size: x-large;
    color: var(--bgColorRed);
  }
}

