

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
}


p{
    padding: 0.5vh 0;
}

/*Footer grid*/

.footer-grid{
    display: grid;
    grid-template-columns: 1.7fr 0.6fr 0.1fr 2.3fr;
    grid-template-rows: auto;
    align-items: center;
    background-color: white;
    color: #FFFFFF;
    font-size: small;
    align-items: center;
    justify-content: center;
}

.image-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 90%;
    width: 90%;
}
#logo-honduautos-2{
    background-color: white;
    height: 80%;
    width: 100%;
}

.information-part{
display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: column;
height: 100%;
padding-left: 10%;
border-bottom-left-radius: 25px;
background-color: var(--bgColorRed);
}

.input-part{
    background-color: var(--bgColorRed);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 1% 0;
}

.foolink{
    color: white;
}

.input-section{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2%;
    height: 80%;
    width: 100%;
}

.line-div{
    height: 100%;
    width: 100%;
    background-color: var(--bgColorRed);
    display: flex;
    align-items: center;
    justify-content: center;
}

.line{
    height: 80%;
    width: 5%;
    background-color: white;
}

.subscribe{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 1%;
}

#email-f{
    width: 70%;
    height: 100%;
    padding: 0.5% 0;
    border-radius: 10px;
    border: none;
    margin-right: 1%;
}

.button-submit{
    padding: 1% 2%;
    border-radius: 10px;
    background-color: white;
    color: var(--bgColorRed);
}

.red-link{
    color: var(--bgColorRed);
}

.checked-part{
    display: flex;
}

.check-box{
    margin-right: 1%;
    width: 3%;
}

.footer-part{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: small;
    color: var(--bgColorRed);
    font-weight: 500;
    justify-content: center;
    align-items: center;
    background-color: var(--bgColorGrey);
}

.right-footer{
    display: flex;
    align-items: center;
}

.right-footer img{
    height: 4vh;
    padding: 0 5%;
}


.foo-logos{
    display: flex;
    align-items: center;
    width: 35%;
    margin-left: 10%;
    justify-content: space-between;
}

.logo-honduatuos-2{
    padding: 50%;
}