

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

@media(min-width:900px){
    .car-updated-1-stock{
        background-image: var(--background-image);
        background-size: cover;
        width: 30%;
        margin: 1%;
        cursor: pointer;
    }

    .info-car-1-stock{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        margin-top: 50%;
    }

    .info-part-stock{
        display: grid;
        align-items: start;
        justify-items: start;
        color: #425860;
    }

    .info-part-stock p{
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .lote-stock{
        font-size: 1.1em;
    }
    
    .price-stock{
        margin: 0;
        font-size: 1.3em;
        font-weight: bold;
    }
    
    .car-model-stock{
        margin: 0;
        font-size: 1.2em;
        font-weight: bold;
    }
    
    .offer-image-stock{
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
    }
}

@media(min-width: 600px) and (max-width: 900px){
    .car-updated-1-stock{
        background-image: var(--background-image);
        background-size: cover;
        width: 90%;
        height: 200px;
        margin: 1%;
    }

    .info-car-1-stock{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        margin-top: 38%;
    }

    .info-part-stock{
        display: grid;
        align-items: start;
        justify-items: start;
        color: #425860;
    }
    
    .price-stock{
        margin: 0;
        font-size: 1.7em;
    }
    
    .car-model-stock{
        margin: 0;
        font-size: 1.2em;
    }
    
    .offer-image-stock{
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
    }
}


@media(max-width:600px){
    .car-updated-1-stock{
        background-image: var(--background-image);
        background-size: cover;
        width: 90%;
        margin: 1%;
        margin-bottom: 5%;

    }

    .info-car-1-stock{
        background-color: rgb(255,255,255,0.7);
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        margin-top: 40%;
    }

    .info-part-stock{
        display: grid;
        align-items: start;
        justify-items: start;
        color: #425860;
    }

    .info-part-stock p{
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    
    .price-stock{
        margin: 0;
        font-size: 1.7em;
    }
    
    .car-model-stock{
        margin: 0;
        font-size: 1.2em;
    }
    
    .offer-image-stock{
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
    }
}