

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --bgColorLightbtn: #BACBCF;
}

.seeall{
    color: white;
}

p{
    padding: 0.5vh 0;
}

ul{
    list-style-type: none;
    padding-left: 0;
}



@media(min-width:900px){
    /*Button style*/
#drop-menu{
    border-color: transparent;
    background-color: var(--bgColorDarkbtn);
    color: white;
    font-size: small;
    margin-left: 5%;
    padding-top: 8%;
    padding-bottom: 8%;
    width: 150%;
    border-radius: 1vh;
    font-weight: 600;
    padding-left: 2%;
    padding-right: 2%;
}

#dropdown a {
    color: white;
}

#arro   {
    transition: transform 0.3s ease;
    width: 13%;
    margin-left: 5%;
}

.arrow-up {
    transform: rotate(180deg);
  }

.menu-drop{
    margin-left: 5%;
    width: 100%;
    background-color: var(--bgColorLightbtn);
    height: 200%;
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    padding:0;
    border-color: 1px solid black;
    border-radius: 0vh 0vh 1vh 1vh;
    z-index: 10;
}

.buttonNoMargin{
    margin: 0;
}

.list-ref{
    color: var(--bgColorDarkbtn);
    font-weight: 600;
    margin: 0.1%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#drop-menu.clicked{
    border-radius: 1vh 1vh 0vh 0vh;
}

.a-color{
    color: var(--bgColorDarkbtn);
    background-color: var(--bgColorLightbtn);
    background-size: cover;
    height: 100%;
}

.line hr{
    padding: 0;
    margin: 0;
    border-color: var(--bgColorDarkbtn);
    width: 100%;
}

.line{
    padding-bottom: 5%;
}

#second{
    padding: 5% 0;
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*Button style*/

    .whole-drop{
        margin: 0 0.5vh;
    }

   
    button#drop-menu{
        padding: 8%;
    }
    
    #drop-menu{
        border-color: transparent;
        background-color: var(--bgColorDarkbtn);
        color: white;
        font-size: xx-small;
        margin-right: 0.5%;
        width: 100%;
        border-radius: 1vh;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        z-index: 999;
    }
    
    #arro   {
        transition: transform 0.3s ease;
        width: 13%;
        margin-left: 5%;
    }
    
    .arrow-up {
        transform: rotate(180deg);
      }
    
    .menu-drop{
        width: 100%;
        background-color: var(--bgColorLightbtn);
        height: 5vh;
        display: grid;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        padding:0;
        border-color: 1px solid black;
        border-radius: 0vh 0vh 1vh 1vh;
        z-index: 1;
    }
    
    .buttonNoMargin{
        margin: 0;
    }
    
    .list-ref{
        color: var(--bgColorDarkbtn);
        font-weight: 600;
        margin: 0.1%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    
    #drop-menu.clicked{
        border-radius: 1vh 1vh 0vh 0vh;
    }
    
    .a-color{
        color: var(--bgColorDarkbtn);
        background-color: var(--bgColorLightbtn);
        background-size: cover;
        height: 100%;
        font-size: xx-small;
    }
    
    .line hr{
        padding: 0;
        margin: 0;
        border-color: var(--bgColorDarkbtn);
    }
    
    .line{
        padding-bottom: 5%;
    }
    
    #second{
        padding: 5% 0;
    }   
}


@media(max-width:600px){
    /*Button style*/
   
    button#drop-menu{
       padding: 9% 0;
    }
    
    #drop-menu{
        border-color: transparent;
        background-color: var(--bgColorDarkbtn);
        color: white;
        font-size: xx-small;
        margin-right: 0.5%;
        width: 100%;
        border-radius: 1vh;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        z-index: 999;
    }
    
    #arro   {
        transition: transform 0.3s ease;
        width: 13%;
        margin-left: 5%;
    }
    
    .arrow-up {
        transform: rotate(180deg);
      }
    
    .menu-drop{
        width: 100%;
        background-color: var(--bgColorLightbtn);
        height: 5vh;
        display: grid;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        padding:0;
        border-color: 1px solid black;
        border-radius: 0vh 0vh 1vh 1vh;
        z-index: 1;
    }
    
    .buttonNoMargin{
        margin: 0;
    }
    
    .list-ref{
        color: var(--bgColorDarkbtn);
        font-weight: 600;
        margin: 0.1%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    
    #drop-menu.clicked{
        border-radius: 1vh 1vh 0vh 0vh;
    }
    
    .a-color{
        color: var(--bgColorDarkbtn);
        background-color: var(--bgColorLightbtn);
        background-size: cover;
        height: 100%;
        font-size: xx-small;
    }
    
    .line hr{
        padding: 0;
        margin: 0;
        border-color: var(--bgColorDarkbtn);
    }
    
    .line{
        padding-bottom: 5%;
    }
    
    #second{
        padding: 5% 0;
    }   
}