

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

.no-notifications {
  text-align: center;
  font-size: 1.5em;
  color: #666;
  margin-top: 20px;
}

.button-notifications.active {
    background-color: #b53232;
  }
    
  .main-container-notification.selected {
    background-color: #949494; 
  }

.delete-noti{
    background-color: transparent;
}

@media(min-width:900px){
    .containers{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10%;
        padding-bottom: 5%;
    }

    .main-container-user{
        background-color: var(--bgColorRed);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
    }

    .column-user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: xx-large;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
    }

    .column-info-n{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 88vh;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: x-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .notifications-grid{
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        height: 70vh;
    }

    .overview-item-n{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
        background-color: transparent;
    }

    .column-content{
        background-color: #F1F1F1;
        margin: 1% 5% ;
        height: 400px;
        border-radius: 10px;
    }

    .column-content-n{
        background-color: #F1F1F1;
        margin: 1% 5% ;
        height: 450px;
        border-radius: 10px;
    }
    
    .from{
        margin: 5% 0 1% 5%;
        font-weight: 500;
        font-size: 1.5em;
    }

    .to{
        margin-left: 5%;
        font-weight: 500;
        font-size: 1.1em;
    }

    .notification-content{
        margin-left: 5%;
        margin-top: 1%; 
    }

    .buttons-notifications{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttons-notification{
        margin-top: 10%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-notifications{
        margin: 0 1%;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: bold;
        padding: 0.5% 0;
        border-radius: 10px;
        cursor: pointer;
    }

    .button-n{
        margin: 0 1%;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: bold;
        padding: 0.5% 1;
        border-radius: 10px;
        font-size: medium;
        cursor: pointer;
    }
}


@media(min-width: 600px) and (max-width: 900px){
    .containers{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10%;
        padding-bottom: 5%;
    }

    .main-container-user{
        width: 90%;
        height: 90vh;
        border-radius: 10px;
    }

    .column-user{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: larger;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
    }

    .column-info-n{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .notifications-grid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .column-overview{
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overview-item-n{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
        background-color: transparent;
    }

    .column-content{
        background-color: #F1F1F1;
        margin: 5%;
        height: 250px;
        max-height: 250px;
        overflow-y: auto;
        border-radius: 10px;
    }
    
    .from{
        margin: 5% 0 1% 5%;
        font-weight: 500;
        font-size: 1.5em;
    }

    .to{
        margin-left: 5%;
        font-weight: 500;
        font-size: 1.1em;
    }

    .notification-content{
        margin-left: 5%;
        margin-top: 1%; 
    }

    .buttons-notifications{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-notifications{
        margin: 0 1%;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: bold;
        padding: 0.5% 0;
        border-radius: 10px;
    }
}

@media(max-width:600px){
    .containers{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10%;
        padding-bottom: 5%;
    }

    .main-container-user{
        width: 90%;
        height: 90vh;
        border-radius: 10px;
    }

    .column-user{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: larger;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
    }

    .column-info-n{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .notifications-grid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .column-overview{
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overview-item-n{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
        background-color: transparent;
    }

    .column-content{
        background-color: #F1F1F1;
        margin: 5%;
        height: 350px;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .from{
        margin: 5% 0 1% 5%;
        font-weight: 500;
        font-size: 1.5em;
    }

    .to{
        margin-left: 5%;
        font-weight: 500;
        font-size: 1.1em;
    }

    .notification-content{
        margin-left: 5%;
        margin-top: 1%; 
        overflow-x: auto;
        max-height: 250px;
    }

    .buttons-notifications{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-notifications{
        margin: 0 1%;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: bold;
        padding: 0.5% 0;
        border-radius: 10px;
    }

    .button-n{
        margin: 0 1%;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: bold;
        padding: 0.5% 1;
        border-radius: 10px;
        font-size: medium;
        margin-top: 50%;
    }
}