:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
  --bgColorAdmin: #aab4b7;
}

button{
  cursor: pointer;
}
 
.white-link{
  color: white;
}

p {
  padding: 0.5vh 0;
  margin: 0;
}

.top-loading-bar {
  height: 8px !important; /* Adjust the height as needed */
  background-color: yellow !important; /* Set the color to purple */
}

@media (min-width: 900px) {
  .images-container-cloudinary {
    margin-left: 5%;
    overflow-x: auto;
    max-width: 600px;
    margin-top: 5%;
  }

  .main-image-container {
    margin-bottom: 20px;
    text-align: left;
  }

  .main-uploaded-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }

  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
  }

  .thumbnail-image-container {
    position: relative;
    margin-right: 10px;
  }

  .thumbnail-uploaded-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }

  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  /* Indicate the selected main image with a border */
  .thumbnail-uploaded-image.selected {
    border: 2px solid blue;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .main-container-admin-add {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 195vh;
    border-radius: 10px;
  }

  .main-container-admin-up {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 195vh;
    border-radius: 10px;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-add {
    background-color: #f7f7f7;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 99%;
  }

  .form-container-add {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0 15%;
    padding: 4%;
    height: 85%;
    width: 80%;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .add-elements {
    width: 125%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1%;
    margin-top: 2%;
  }

  #marca {
    width: 14vw;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  #modelo {
    width: 13.5vw;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  .add-element-btn {
    width: 9%;
    padding: 0;
    background-color: transparent;
    margin: 0;
    margin-left: 1%;
  }

  .add-element-img {
    width: 1.5vw;
    height: 3vh;
  }

  .form-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 4%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1%;
    width: 90%;
  }

  .lote {
    display: flex;
  }

  .lote input {
    padding: 1% 0;
  }

  .links {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
  }

  .widget {
    width: 25%;
    margin-bottom: 0;
  }

  .link-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    height: 11vh;
  }

  .twolinksbox {
    display: flex;
    flex-direction: column;
  }

  .fire-button {
    width: 100%;
    padding: 0 1%;
  }

  .fire {
    margin: 0 1%;
    margin-left: 5%;
    padding: 0;
    width: 2vw;
    height: 4vh;
    border-radius: 1vh;
  }

  .span-filter {
    display: flex;
    align-items: center;
    height: 2.5vh;
  }

  .sell-input {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
  }

  .sell-input-2 {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
  }

  .elements-popup {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5% 10%;
    width: 35vw;
    align-items: center;
    border-radius: 10px;
  }

  .popup-title {
    font-size: xx-large;
    width: 30vw;
    font-weight: 500;
  }

  .popup-input {
    width: 25vw;
    background-color: transparent;
    max-height: 3vh;
  }

  .popup-btn {
    background-color: var(--bgColorRed);
    margin-top: 2%;
    color: white;
    font-size: large;
    font-weight: 500;
    border-radius: 5px;
    padding: 1% 2%;
  }

  .images {
    display: flex;
    width: 8.5%;
    margin: 1% 0;
  }

  .grid-sell {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    margin: 0;
  }

  .grid-sell-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 0;
    margin: 0;
  }

  .columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .columns input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns select {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .columns-2 input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 1.5% 0;
  }

  .add-car {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-large;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 5%;
  }
  .grid-sell-checks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    margin: 0;
    align-items: center;
    margin: 1% 4% 0 4%;
  }
  .columnsdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    font-size: small;
    font-weight: 500;
  }

  .columnsdown input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 5px 3px;
    padding: 0;
    vertical-align: middle;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .images-container-cloudinary {
    overflow-x: auto;
    max-width: 300px;
    margin-top: 5%;
  }

  .main-image-container {
    margin-bottom: 20px;
    text-align: center;
  }

  .main-uploaded-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }

  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 10px 0;
  }

  .thumbnail-image-container {
    position: relative;
    margin-right: 10px;
  }

  .thumbnail-uploaded-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }

  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  /* Indicate the selected main image with a border */
  .thumbnail-uploaded-image.selected {
    border: 2px solid blue;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .main-container-admin-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 45%;
  }

  .main-container-admin-up {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 195vh;
    border-radius: 10px;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-add {
    background-color: #f7f7f7;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 99%;
  }

  .form-container-add {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 15%;
    padding: 4%;
    height: 75%;
    width: 80%;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .add-elements {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1%;
    margin-top: 2%;
  }

  #marca {
    width: 100%;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  #modelo {
    width: 100%;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  .add-element-btn {
    width: 15%;
    padding: 0;
    background-color: transparent;
    margin: 0;
    margin-left: 1%;
  }

  .add-element-img {
    width: 100%;
  }

  .form-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 4%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1%;
    width: 90%;
  }

  .lote {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .lote input {
    padding: 1% 0;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .widget {
    margin-bottom: 0;
  }

  .link-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

  }

  .check-add{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .twolinksbox {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
  }

  .fire-button {
    width: 25px;
  }

  .fire {
    margin: 0 1%;
    padding: 1% 5%;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .span-filter {
    display: flex;
    align-items: flex-start;
  }

  .sell-input {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
    width: 100%;
  }

  .sell-input-2 {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
    width: 100%;
  }

  .elements-popup {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5% 10%;
    align-items: center;
    border-radius: 10px;
  }

  .popup-title {
    font-size: x-large;
    text-align: center;
    font-weight: 500;
  }

  .popup-input {
    width: 80%;
    background-color: transparent;
    max-height: 3vh;
  }

  .popup-btn {
    background-color: var(--bgColorRed);
    margin-top: 5%;
    color: white;
    font-size: large;
    font-weight: 500;
    border-radius: 5px;
    padding: 1% 2%;
  }

  .images {
    display: flex;
    width: 8.5%;
    margin: 1% 0;
  }

  .grid-sell {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .grid-sell-2 {
    display:flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .columns {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .columns input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns select {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .columns-2 input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .add-car {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-large;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 5%;
  }
  .grid-sell-checks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    align-items: flex-start;
    margin: 1% 4% 0 4%;
  }
  .columnsdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    font-size: small;
    font-weight: 500;
  }

  .columnsdown input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 5px 3px;
    padding: 0;
    vertical-align: middle;
  }
}


@media (max-width: 600px) {
  .images-container-cloudinary {
    overflow-x: auto;
    max-width: 300px;
    margin-top: 5%;
  }

  .main-image-container {
    margin-bottom: 20px;
    text-align: center;
  }

  .main-uploaded-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }

  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 10px 0;
  }

  .thumbnail-image-container {
    position: relative;
    margin-right: 10px;
  }

  .thumbnail-uploaded-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }

  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  /* Indicate the selected main image with a border */
  .thumbnail-uploaded-image.selected {
    border: 2px solid blue;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .main-container-admin-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 45%;
  }

  .main-container-admin-up {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 195vh;
    border-radius: 10px;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info-add {
    background-color: #f7f7f7;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 99%;
  }

  .form-container-add {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 15%;
    padding: 4%;
    height: 75%;
    width: 80%;
  }

  .main-admin-title {
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 500;
    margin-bottom: 3%;
  }

  .add-elements {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1%;
    margin-top: 2%;
  }

  #marca {
    width: 100%;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  #modelo {
    width: 100%;
    height: 5vh;
    margin: 0;
    font-weight: 500;
  }

  .add-element-btn {
    width: 15%;
    padding: 0;
    background-color: transparent;
    margin: 0;
    margin-left: 1%;
  }

  .add-element-img {
    width: 100%;
  }

  .form-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 4%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1%;
    width: 90%;
  }

  .lote {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .lote input {
    padding: 1% 0;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .widget {
    margin-bottom: 0;
  }

  .link-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

  }

  .check-add{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .twolinksbox {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
  }

  .fire-button {
    width: 25px;
  }

  .fire {
    margin: 0 1%;
    padding: 1% 5%;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .span-filter {
    display: flex;
    align-items: flex-start;
  }

  .sell-input {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
    width: 100%;
  }

  .sell-input-2 {
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    border-radius: 1vh;
    margin: 0 1% 3% 0;
    padding: 5% 0;
    height: 4vh;
    width: 100%;
  }

  .elements-popup {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5% 10%;
    align-items: center;
    border-radius: 10px;
  }

  .popup-title {
    font-size: x-large;
    text-align: center;
    font-weight: 500;
  }

  .popup-input {
    width: 80%;
    background-color: transparent;
    max-height: 3vh;
  }

  .popup-btn {
    background-color: var(--bgColorRed);
    margin-top: 5%;
    color: white;
    font-size: large;
    font-weight: 500;
    border-radius: 5px;
    padding: 1% 2%;
  }

  .images {
    display: flex;
    width: 8.5%;
    margin: 1% 0;
  }

  .grid-sell {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .grid-sell-2 {
    display:flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .columns {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .columns input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns select {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .columns-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .columns-2 input {
    height: 100%;
    border-color: transparent;
    border-radius: 1vh;
    background-color: var(--bgColorDarkGrey);
    margin: 5px 3px;
    padding: 3% 0;
  }

  .add-car {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-large;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 5%;
  }
  .grid-sell-checks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    align-items: flex-start;
    margin: 1% 4% 0 4%;
  }
  .columnsdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    font-size: small;
    font-weight: 500;
  }

  .columnsdown input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 5px 3px;
    padding: 0;
    vertical-align: middle;
  }
}
