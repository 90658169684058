:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
  --bgColorAdmin: #aab4b7;
}



p {
  padding: 0.5vh 0;
  margin: 0;
}

@media (min-width: 900px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }



  .main-container-admin-a {
    background-color: var(--bgColorAdmin);
    width: 90%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 110vh;
    border-radius: 10px;
  }

  .column-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #949a9c;
    border-radius: 10px;
    margin-top: 5%;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5% 0;
  }

  .element-img {
    width: 11%;
    margin-left: 10%;
  }

  .element-text {
    color: white;
    margin-left: 3%;
  }

  #drop-movil {
    display: none;
  }

  .element-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    margin: 5% 0;
  }

  .column-info {
    background-color: white;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .column-info-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .column-info-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .status {
    display: flex;
    justify-content: space-between;
    width: 75%;
  }

  .user-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1%;
    background-color: #e6e6e6;
    margin-bottom: 2%;
    border-radius: 10px;
  }

  .status-title {
    font-weight: 600;
  }

  .status-property {
    font-weight: 600;
    color: var(--bgColorRed);
  }

  .main-admin-title-a {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 2%;
  }

  .overview-container-admin {
    width: 90%;
  }

  .overview-item-boletin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
    border-radius: 10px;
  }

  .column-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 500;
  }

  .search-boletin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1%;
  }

  .search-bar-mod {
    position: relative;
    width: 100%;
    margin-right: 1%;
  }

  .search-input {
    width: 45%;
    padding: 1% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    border-radius: 10px;
    padding-left: 1%;
  }

  .search-input::placeholder {
    font-weight: 600;
  }

  .filter-label {
    margin-left: 2%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .filter-container {
    display: flex;
    width: 30%;
  }

  .search-bar-a-movil{
    display: none;
  
  }
}

@media (min-width: 600px) and (max-width: 900px){
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .main-container-admin-a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 45%;
  }

  .whole-drop-a {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #arrows {
    width: 5%;
    margin-left: 2%;
  }

  .admin-menu-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgColorAdmin);
    width: 80%;
    border-radius: 0 0 15px 15px;
    transition: max-height 0.3s ease-in-out;
  }

  .drop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    left: 0;
    z-index: 1000;
  }

  #drop-movil {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 2% 0;
    border-radius: 15px;
    transition: border-radius 0.3s, border-bottom-left-radius 0.3s,
    border-bottom-right-radius 0.3s;
  }

  #drop-movil.click {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .d-text{
    font-weight: 0;
  }

  .column-admin {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    display: none;
  }

  .element-d {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3% 0;
  }

  .element-img-d {
    width: 8%;
    margin-left: 10%;
  }

  .element-text-d {
    color: white;
    margin-left: 3%;
  }

  .element-end-d {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15%;
    margin: 5% 0;
  }

  .column-info {
    background-color: white;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .column-info-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .user-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1%;
    background-color: #e6e6e6;
    margin-bottom: 2%;
    border-radius: 10px;
    width: 60%;
  }

  .status-title {
    font-weight: 600;
  }

  .status-property {
    font-weight: 600;
    color: var(--bgColorRed);
  }

  .main-admin-title-a {
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .search-bar-a{
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .search-bar-ac{
    width: 80%;
  }

  .search-bar-a-movil{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }


  #Search-m {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-radius 0.3s ease;
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 1% 0;
    padding-left: 3%;
    font-size: medium;
    position: relative;
  }

  #Search-m::placeholder {
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  #search-m {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 3% 0;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50%;
  }

  #search-b {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 3% 0;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50%;
  }

.suggestions-list-m {
    width: 100%; 
    position: absolute;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; 
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 85%;
    margin-left: 1%;
  } 

  .suggestion-item-m {
    padding: 1% 0;
    margin-left: 3%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
  } 

  .search-bar-ac.show-suggestions #Search-m {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .overview-item-boletin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 2% 0;
    border-radius: 10px;
  }

  .column-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 500;
  }

  .search-boletin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1%;
  }

  .search-input {
    width: 45%;
    padding: 1% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    border-radius: 10px;
    padding-left: 1%;
  }

  .search-input::placeholder {
    font-weight: 600;
  }

  .filter-label {
    margin-left: 2%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .filter-container {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .main-container-admin-a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-top: 45%;
  }

  .whole-drop-a {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #arrows {
    width: 5%;
    margin-left: 2%;
  }

  .admin-menu-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgColorAdmin);
    width: 80%;
    border-radius: 0 0 15px 15px;
    transition: max-height 0.3s ease-in-out;
  }

  .drop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    left: 0;
    z-index: 1000;
  }

  #drop-movil {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 2% 0;
    border-radius: 15px;
    transition: border-radius 0.3s, border-bottom-left-radius 0.3s,
    border-bottom-right-radius 0.3s;
  }

  #drop-movil.click {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .d-text{
    font-weight: 0;
  }

  .column-admin {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .admin-name {
    color: white;
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 10%;
    padding: 0 5%;
  }

  .admin-menu {
    display: none;
  }

  .element-d {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3% 0;
  }

  .element-img-d {
    width: 8%;
    margin-left: 10%;
  }

  .element-text-d {
    color: white;
    margin-left: 3%;
  }

  .element-end-d {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15%;
    margin: 5% 0;
  }

  .column-info {
    background-color: white;
    margin-top: 2%;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .column-info-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .user-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1%;
    background-color: #e6e6e6;
    margin-bottom: 2%;
    border-radius: 10px;
    width: 60%;
  }

  .status-title {
    font-weight: 600;
  }

  .status-property {
    font-weight: 600;
    color: var(--bgColorRed);
  }

  .main-admin-title-a {
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .search-bar-a{
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .search-bar-ac{
    width: 80%;
  }

  .search-bar-a-movil{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }


  #Search-m {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-radius 0.3s ease;
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 1% 0;
    padding-left: 3%;
    font-size: medium;
    position: relative;
  }

  #Search-m::placeholder {
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  #search-m {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 3% 0;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50%;
  }

  #search-b {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 3% 0;
    border-radius: 1vh;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50%;
  }

.suggestions-list-m {
    width: 100%; 
    position: absolute;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; 
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 85%;
    margin-left: 1%;
  } 

  .suggestion-item-m {
    padding: 1% 0;
    margin-left: 3%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
  } 

  .search-bar-ac.show-suggestions #Search-m {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .overview-item-boletin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 2% 0;
    border-radius: 10px;
  }

  .column-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 500;
  }

  .search-boletin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1%;
  }

  .search-input {
    width: 45%;
    padding: 1% 0;
    border-color: transparent;
    background-color: #d9d9d9;
    border-radius: 10px;
    padding-left: 1%;
  }

  .search-input::placeholder {
    font-weight: 600;
  }

  .filter-label {
    margin-left: 2%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .filter-container {
    display: flex;
    width: 100%;
  }
}
