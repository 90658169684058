

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}


p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    .container-u{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10%;
    }

    .main-container-user{
        background-color: var(--bgColorRed);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
        margin-bottom: 5%;
    }

    .main-container-user-user{
        background-color: var(--bgColorRed);
        width: 90%;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        height: 90vh;
        border-radius: 10px;
        margin-bottom: 5%;
    }

    .column-user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: larger;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: x-large;
        font-weight: 500;
        margin-bottom: 1%;
    }

    .overview-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
    }

    .add-car-btn{
        background-color: var(--bgColorRed);
        color: white;
        margin-top: 3%;
        border-color: transparent;
        width: 25%;
        font-size: 1em;
        padding: 1% 0;
        font-weight: 600;
        border-radius: 10px;
    }
}

@media(min-width: 600px) and (max-width: 900px){
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:5% ;
    }

    .main-container-user{
        padding-top: 40%;
        border-radius: 10px;
    }

    .main-container-user-user{
        padding-top: 40%;
        border-radius: 10px;
    }

    .whole-drop-u {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      #arrows {
        width: 5%;
        margin-left: 2%;
      }


    .drop{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 100%;
        z-index: 1000;
    }

    .user-menu-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--bgColorRed);
        width: 80%;
        border-radius: 0 0 15px 15px;
        transition: max-height 0.3s ease-in-out;
      }

    .column-user{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: larger;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: xx-large;
        font-weight: 500;
        margin-bottom: 1%;
    }

    .overview-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
    }

    .add-car-btn{
        background-color: var(--bgColorRed);
        color: white;
        margin-top: 3%;
        border-color: transparent;
        width: 25%;
        font-size: 1em;
        padding: 1% 0;
        font-weight: 600;
        border-radius: 10px;
    }
}

@media(max-width:600px){
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:5% ;
    }

    .main-container-user{
        padding-top: 20%;
        border-radius: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-container-user-user{
        padding-top: 40%;
        border-radius: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .whole-drop-u {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      #arrows {
        width: 5%;
        margin-left: 2%;
      }


    .drop{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 100%;
        z-index: 1000;
    }

    .user-menu-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--bgColorRed);
        width: 80%;
        border-radius: 0 0 15px 15px;
        transition: max-height 0.3s ease-in-out;
      }

    .column-user{
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .user-img{
        width: 50%;
        margin-top: 5%;
    }
    
    .user-name{
        color: white;
        font-weight: bold;
        font-size: larger;
        margin-top: 5%;
    }

    .user-menu{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #C4332D;
        border-radius: 10px;
        margin-top: 5%;
    }

    .element{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5% 0;
    }

    .element-img{
        width: 11%;
        margin-left: 10%;
    }

    .element-text{
        color: white;
        margin-left: 3%;
    }

    .element-end{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 25%;
        margin: 5% 0;
        
    }

    .column-info{
        background-color: white;
        margin-top: 2%;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .main-user-title{
        margin-top: 2%;
        font-size: x-large;
        font-weight: 500;
        margin-bottom: 1%;
    }

    .overview-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0;
    }

    .add-car-btn{
        background-color: var(--bgColorRed);
        color: white;
        margin-top: 3%;
        border-color: transparent;
        width: 25%;
        font-size: 1em;
        padding: 1% 0;
        font-weight: 600;
        border-radius: 10px;
    }
}