

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

/*Stock style*/

.menu-stock{
    background-color: #E8E7E7;
    flex-direction: row;    
    margin: 3% 5%;
    padding-bottom: 2%;
    display: flex;
    border-radius: 1vh;
    justify-content: center;
    
}

.filter-title{
    width: 15%;
    padding-top: 1%;
    margin-left: 2%;
    display: flex;
    font-size: larger;
    justify-content: center;
    font-weight: 700;
}

#Search-s{
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 0.5% 0;
    width: 30%;
    font-size: medium;
    border-radius: 1vh;
    margin-right: 0.5%;
}