

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}


p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

li{
    font-size: medium;
    font-weight: normal;
}

.phone-i{
    margin-bottom: 2%;
    margin-top: 1%;
}

#dropdown, #vivienda_type, #laboral {
    width: 100%;
  }
  
  .phone-i, .select {
    width: 100%;
  }
  
  .phone-i .react-tel-input {
    width: 100%;
  }
  
  .phone-i .react-tel-input .form-control {
    width: 100%;
    padding-left: 10%;
  }
  
  .select .react-select__control {
    width: 100%;
  }

@media(min-width:900px){
    .movil-footer{
        display: none;
    }

    .input-grid-m{
        display: none;
    }
    /*Credit style*/
.credit-container{
    width: 100%;
    height: 100%;
    padding-top: 10%;
}

#credit-title{
    font-size: xx-large;
    margin-left: 5%;
    font-weight: 500;
}

.column-title{
    margin-left: 5%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 3%;
}

.grid-info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2%;
    margin: 0 5%;
    font-size: large;
    font-weight: 500;
    text-align: justify;
    margin-top: 0;
    padding-top: 0;
}

.title{
    font-size: larger;
    font-weight: 600;
    margin: 2% 0;
}

.list-title{
    font-size: large;
    font-weight: 500;
}

.column{
    display: flex;
    align-items: center;
    justify-content: center;
}

.columns-c{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.columns-c input{
    padding: 1.2% 2%;
    margin: 1% 0;
    width: 100%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

.columns-c input::placeholder{
    color: gray;
    font-weight: 300;
    font-size: smaller;
    margin: 0;
    padding: 0;
}

.column:first-child{
    margin-right: 2%;
}

.column:last-child{
    margin-left: 2%;
}

.checkbox-area{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: 20%;
}

.text-checkbox{
    align-self: flex-start;
    margin-left: 10%;
    font-size: small;
    font-weight: 500;
}

input#checkbox{
    width: 5%;
}

#checkbox-2{
    margin-left: 3%;
    width: 5%;
}

.vehiculo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.checkbox{
    margin-left: 1%;
}


input#direccion{
    padding: 1% 2%;
    width: 80%;
}



input#direccion::placeholder{
    color: gray;
    font-weight: 300;
    font-size: small;
    margin: 0;
    padding: 0;
}

.center-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 8%;
    align-items: center;
    justify-content: center;
}

.columns select::placeholder{
    color: black;
}

.columns{
    display: flex;
    flex-direction: column;
    margin: 0 10%;
}

.payment-buttons{
    display: flex;
    justify-content: center;

}

#direccion{
    padding: 2% 3%;
    margin: 1% 0;
    width: 75%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

#sumbit-form{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 0.5% 2%;
    font-weight: 500;
    font-size: large;
    border-radius: 1vh;
}

/*calculo de la mensualidad*/
.payment{
    background-color: #EDECEC;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 1% 1%;
    margin: 2% 0;
    border-radius: 1vh;
    width: 60%;
}

.input-payment{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
}

.payment p{
    align-self: flex-start;
    font-size: large;
    font-weight: 500;
    margin-bottom: 1%;
}

#payment-input{
    padding: 1%;
    border-radius: 1vh;
    width: 30%;
    margin: 0 1%;
    border-color: transparent;
}

.payment-input{
    display: flex;
    width: 100%;
    margin-bottom: 2%;
}

.payment input::placeholder{
    margin: 0;
    padding: 0;
    font-size: small;
    color: lightgray;   
    text-align: center;
}

.terms{
    display: flex;
    width: 60%;
    text-align: justify;
    font-size: small;
}

.terms-modal{
    display: flex;
    width: 100%;
    margin-top: 5%;
    text-align: justify;
    font-size: small;
}

#checkbox-terms{
    transform: scale(1.2);
    margin-right: 1%;
}

.last-p{
    margin: 1% 5% 3% 5%;
    font-size: small;
}

.send-form{
    background-color: var(--bgColorYellow);
    padding: 0.5% 1%;
    border-radius: 10px;
    margin: 1% 0.5%;
    font-weight: bold;
}

.send-form-2{
    background-color: var(--bgColorRed);
    color: white;
    padding: 0.5% 1%;
    border-radius: 10px;
    margin: 1% 0%;
    font-weight: bold;
}

.div-credit-btns{
    display: flex;
    justify-content: center;
    margin: 2% 0;	
}
}

@media(min-width: 600px) and (max-width: 900px){
    /*Credit style*/
    .web-footer{
        display: none;
    }

    .payment-buttons{
        display: flex;
        justify-content: center;
    
    }

.credit-container{
    width: 100%;
    height: 100%;
    padding-top: 45%;
}

#credit-title{
    font-size: xx-large;
    margin-left: 5%;
    font-weight: 500;
}

.column-title{
    margin-left: 5%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 3%;
}


.title{
    font-size: larger;
    font-weight: 600;
    margin: 2% 0;
}

.list-title{
    font-size: large;
    font-weight: 500;
}

.column{
    display: flex;
    align-items: center;
    justify-content: center;
}

.column input{
    padding: 2% 3%;
    margin: 1% 0;
    width: 75%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

.column input::placeholder{
    color: black;
    font-weight: 500;
}



.checkbox-area{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: 20%;
}

.text-checkbox{
    align-self: flex-start;
    margin-left: 10%;
    font-size: small;
    font-weight: 500;
}

input#checkbox{
    width: 5%;
}

#checkbox-2{
    margin-left: 3%;
    width: 5%;
}


input#address{
    padding: 1% 2%;
    width: 65%;
}

input::placeholder{
    color: black;
    font-weight: 500;
}

input#address::placeholder{
    color: black;
    font-weight: 500;
    padding-left: 0;
}

.input-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 8%;
}

.column{
    display: flex;
    flex-direction: column;
    margin: 0 10%;
}

.input{
    display: flex;
    flex-direction: column;
}

.input-payment

#address{
    padding: 2% 3%;
    margin: 1% 0;
    width: 75%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

#sumbit-form{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 0.5% 2%;
    font-weight: 500;
    font-size: large;
    border-radius: 1vh;
}

/*calculo de la mensualidad*/
.payment{
    background-color: #EDECEC;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 1% 1%;
    margin: 2% 0;
    border-radius: 1vh;
    width: 90%;
}

.input-payment{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
}

.payment p{
    align-self: flex-start;
    font-size: large;
    font-weight: 500;
    margin-bottom: 1%;
}

#payment-input{
    padding: 1%;
    border-radius: 1vh;
    width: 30%;
    margin: 0 1%;
    border-color: transparent;
}

.payment-input{
    display: flex;
    width: 100%;
    margin-bottom: 2%;
}

.payment input::placeholder{
    margin: 0;
    padding: 0;
    font-size: small;
    color: lightgray;   
    text-align: center;
}

.terms{
    display: flex;
    width: 90%;
    text-align: justify;
    font-size: small;
    justify-content: flex-start;
    align-items: flex-start;
}

#checkbox-terms{
    transform: scale(1.2);
    margin-right: 1%;
    margin-top: 2%;
}

.last-p{
    margin: 1% 5% 3% 5%;
    font-size: small;
    font-weight: 400;
    text-align: justify;
}
}


.div-credit-btns{
    display: flex;                  
    justify-content: center;        
    align-items: center;            
}

@media(max-width: 600px){
    /*Credit style*/
    .web-footer{
        display: none;
    }

    .center-grid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .payment-buttons{
        display: flex;
        justify-content: center;
    
    }

.credit-container{
    width: 100%;
    height: 100%;
    padding-top: 50%;
}

#credit-title{
    font-size: xx-large;
    margin-left: 5%;
    font-weight: 500;
}

.column-title{
    margin-left: 5%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 3%;
}

.title-c{
    margin-top: 15%;
}


.title{
    font-size: larger;
    font-weight: 600;
    margin: 2% 0;
}

.list-title{
    font-size: large;
    font-weight: 500;
}

.columns-c{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.columns-c input{
    margin: 1% 0;
    width:100%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

.column input::placeholder{
    color: black;
    font-weight: 500;
}



.checkbox-area{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: 20%;
}

.text-checkbox{
    align-self: flex-start;
    margin-left: 10%;
    font-size: small;
    font-weight: 500;
}

input#checkbox{
    width: 5%;
}

#checkbox-2{
    margin-left: 3%;
    width: 5%;
}

.send-form{
    background-color: var(--bgColorYellow);
    padding: 0.5% 4%;
    border-radius: 10px;
    margin: 1% 1%;
    font-weight: bold;
}

.send-form-2{
    background-color: var(--bgColorRed);
    padding: 0.5% 4%;
    border-radius: 10px;
    margin: 1% 1%;
    font-weight: bold;
}

input#address{
    padding: 1% 2%;
    width: 65%;
}

input::placeholder{
    color: black;
    font-weight: 500;
}

input#address::placeholder{
    color: black;
    font-weight: 500;
    padding-left: 0;
}

.input-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 8%;
}

.column{
    display: flex;
    flex-direction: column;
    margin: 0 10%;
}

.input{
    display: flex;
    flex-direction: column;
}

.input-payment

#address{
    padding: 2% 3%;
    margin: 1% 0;
    width: 75%;
    border-color: transparent;
    background-color: #EDECEC;
    border-radius: 1vh;
}

#sumbit-form{
    background-color: var(--bgColorYellow);
    border-color: transparent;
    margin: 2% 0;
    padding: 0.5% 2%;
    font-weight: 500;
    font-size: large;
    border-radius: 1vh;
}

.vehiculo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/*calculo de la mensualidad*/
.payment{
    background-color: #EDECEC;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 1% 1%;
    margin: 2% 0;
    border-radius: 1vh;
    width: 90%;
}

.input-payment{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
}

#direccion{
    width: 78%;
    padding: 2% 0;
}

.payment p{
    align-self: flex-start;
    font-size: large;
    font-weight: 500;
    margin-bottom: 1%;
}

#payment-input{
    padding: 1%;
    border-radius: 1vh;
    width: 30%;
    margin: 0 1%;
    border-color: transparent;
}

#payment-input::placeholder{
    color: white;
}

.payment-input{
    display: flex;
    width: 100%;
    margin-bottom: 2%;
}

.payment input::placeholder{
    margin: 0;
    padding: 0;
    font-size: small;
    color: lightgray;   
    text-align: center;
}

.terms{
    display: flex;
    width: 90%;
    text-align: justify;
    font-size: small;
    justify-content: flex-start;
    align-items: flex-start;
}

#checkbox-terms{
    transform: scale(1.2);
    margin-right: 1%;
    margin-top: 2%;
}

.last-p{
    margin: 1% 5% 3% 5%;
    font-size: small;
    font-weight: 400;
    text-align: justify;
}
}