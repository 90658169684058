@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
}

*{
  font-family: "Poppins", sans-serif;
}

input:focus,
textarea:focus,
select:focus {
  outline: none; /* Elimina el borde de enfoque predeterminado */
  border-color: inherit; /* Mantiene el color del borde */
  box-shadow: none; /* Elimina cualquier sombra */
}


p {
  padding: 0.5vh 0;
  margin: 0;
}

img {
  margin: 0;
  padding: 0;
}

button {
  border-color: transparent;
}

h1 {
  margin: 0;
  padding: 0;
}

#recent-cars.active {
    background-color: #404145;
  
}

#offer-cars.active {
  background-color: #78201d;
}


@media (min-width: 900px) {
  /*HOME STYLE*/
  .search-bar-ac {
    position: relative;
    width: 70%;
    margin-right: 1%;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    position: relative;
  }

  .div-home{
    padding-top: 7.5%;
    margin-bottom: 3%;
    background-color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-home-style {
    color: white;
    background-color: black;
    padding: 0.5% 0%;
    font-size: medium;
    font-weight: 600;
    transition: all 0.3s ease;
    position: relative; /* Asegura que z-index funcione */
    z-index: 10; /* Un valor alto para que esté por encima de otros elementos */
}
 

  #first-btn{
    border-radius: 1vh 0 0 1vh;
  }

  #last-btn{
    border-radius: 0 1vh 1vh 0;
  }

  .btn-home-style:hover {
    background-color: white;
    color: black;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 100; /* Asegura que el hover esté por encima */
}

  #middle-btn{
    border-left: white 1px solid;
    border-right: white 1px solid;
  }

  .home-btn{
    border-color: var(--bgColorRed);
    margin: 2% 1% 0% 1%;
    font-size: large;
    padding: 1% 2%;
    border-radius: 1vh;
    font-weight: 600;
    position: relative;
    background: none;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
  }

  .home-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/images/button-back.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }

  .home-btn:hover::before {
    opacity: 1;
  }

  .ref-home{
    color: black;
  }
  
  .home-btn:hover,
  .home-btn:hover .ref-home {
    color: white;
  }

  .home-btn:hover {
    color: white;
  }

  .search-presentation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 5%;
    padding: 4.5% 0;
    border-radius: 15px;
    background-image: url("../../public/images/banner20.jpg");
    background-size: cover; /* Hace que la imagen cubra todo el contenedor */
    background-position: center; /* Centra la imagen en el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
}

  .search-ttl{
    color: white;
    font-weight: 800;
    font-size: x-large;
    margin-bottom: 1%;
    margin-top: 1.2%;
  }

  .search-actions{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .search-bar-home {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .search-bar-a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    padding-top: 1%;
  }

  #Search {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-radius 0.3s ease;
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 1% 0;
    padding-left: 3%;
    font-size: medium;
  }

  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; /* Asegúrate de que la lista de sugerencias aparezca por encima de otros elementos */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .suggestions-list-mod {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1% 0 1% 3%;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; /* Asegúrate de que la lista de sugerencias aparezca por encima de otros elementos */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .suggestion-item {
    padding: 1% 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
    padding-left: 3%;
  }

  .suggestion-item:hover {
    background-color: #f0f0f0;
  }

  /* Estilos cuando la lista de sugerencias está visible */
  .search-bar-ac.show-suggestions #Search {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-bar-mod.show-suggestions #Search {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }


  .content {
    padding: 50%;
  }

  .footer-movil {
    display: none;
  }

  .scroll-type {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }

  .update-cars {
    display: flex;
    overflow-x: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5%;
  }

  #Search::placeholder {
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  #search {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: small;
    margin-left: 4%;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
  }

  #search-b {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: large;
    margin-left: 1%;
    margin-top: 2%;
    padding: 0.5% 1%;
    border-radius: 1vh;
    font-weight: 600;
  }

  /*Search by type*/
  .search-by-type {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    border-radius: 1vh;
    background-color: rgb(239, 237, 237);
    position: relative;
    align-items: center;
  }

  .search-title {
    font-size: x-large;
    font-weight: 700;
    color: var(--textColor);
    padding-left: 5%;
    align-self: flex-start;
    margin-top: 1%;
  }

  .scroll-type {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 2%;
    scroll-behavior: smooth;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .scroll-type::-webkit-scrollbar {
    display: none;  
  }

  .car-type {
    width: 100%;
    padding: 2% 3%;
    margin: 1% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


.arrow-h {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 0;
  width: 3%;
}

.arrow-img{
width: 100%;
padding: 0;
margin: 0;
}

.right-arrow {
  right: 0;
  width: 3%;
}

  .car {
    width: 300%;
  }

  #camioneta {
    width: 250%;
  }

  #pick-up {
    width: 350%;
  }

  #camion {
    width: 275%;
  }

  #camioncito {
    width: 225%;
  }

  #cabezal {
    width: 350%;
  }

  #bus {
    width: 575%;
  }

  .car-name-type {
    width: fit-content;
    margin: 0;
    font-weight: 700;
    text-decoration: underline;
    color: #000;
  }

  /*Offers*/
  .offers {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: rgb(239, 237, 237);
  }

  .offers.recent-bg {
  background-color: #7a96d4;
}

.offers.offer-bg {
  background-color: #efb04a;
}

  .buttons {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    padding: 2% 3%;
  }

  .btnCar {
    margin: 1%;
    width: 30vh;
    color: white;
    border-radius: 1vh;
    padding: 2%;
    font-size: large;
    font-weight: 500;
  }

  #recent-cars {
    background-color: #6f737b;
  }

  #offer-cars {
    background-color: var(--bgColorRed);
  }



  /*Cars updated*/

  .update-cars {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }

  .car-updated-1 {
    background-image: var(--background-image);
    background-size: cover;
    width: 25%;
    height: 100%;
    padding: 10% 0 1% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .car-updated-2 {
    background-image: var(--background-image);
    background-size: cover;
    width: 25%;
    height: 100%;
    padding: 10% 0 1% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .info-car-1 {
    background-color: rgb(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 2%;
  }

  .info-part {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: start;
    color: #425860;
    margin-left: 3%;
  }

  .price {
    margin: 0;
  }

  .car-model {
    margin: 0;
  }

  .offer-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
  }

  .new-car {
    width: 75%;
  }

  /*Logos and ads*/

  .container {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }

  

  .ads-container {
    width: 30%;
    margin: 0 2%;
    margin-bottom: 2%;
    display: flex;
    height: 85vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .ads-container-1 {
    width: 30%;
    margin: 0 2%;
    margin-bottom: 2%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ad-1 {
    width: 100%;
    margin: 0 10%;
    height: fit-content;
    margin-bottom: 3%;
    border-radius: 1vh;
  }

  .ad-2 {
    width: 100%;
    margin: 0 10%;
    height: 100%;
    margin-top: 3%;
    border-radius: 1vh;
  }

  .ad-3 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
    display: none;
  }
}


@media (min-width: 600px) and (max-width: 900px) {
  /*HOME STYLE*/

  .div-home{
    padding-top: 55%;
  }

  .btn-home-style {
    color: white;
    background-color: black;
    padding: 0.5% 2%;
    font-size: normal;
    font-weight: 600;
    transition: all 0.3s ease; 
    display: flex;
    padding: 1% 2%;
    align-items: center;
    justify-content: center;
    width: 60vw;
    margin: 2% 0;
    border-radius: 1vh;
 }

  .btn-home-style:hover {
    color: black;
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .home-btn{
    border-color: var(--bgColorRed);
    margin: 2% 1% 0% 1%;
    font-size: large;
    padding: 1% 2%;
    border-radius: 1vh;
    font-weight: 600;
    position: relative;
    background: none;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
  }

  .home-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/images/button-back.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }

  .home-btn:hover::before {
    opacity: 1;
  }

  .ref-home{
    color: black;
  }
  
  .home-btn:hover,
  .home-btn:hover .ref-home {
    color: white;
  }

  .home-btn:hover {
    color: white;
  }

    /*search-bar*/
    .search-bar {
      display: flex;
      padding-top: 50%;
      margin: 0 5%;
      align-items: center;
    }

  /*search-bar*/
  .search-bar-home {
    display: flex;
    padding-top: 5%;
    margin: 0 5%;
    align-items: center;
    
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    display: none;
  }
  
  .left-arrow {
    left: 0;
    width: 3%;
  }
  
  .arrow-img{
  width: 100%;
  padding: 0;
  margin: 0;
  }
  
  .right-arrow {
    right: 0;
    width: 3%;
  }

  .search-bar-ac {
    position: relative;
    width: 60%;
    margin-right: 1%;
  }

  .search-ttl{
    display: none;
  }

  .search-presentation{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .search-actions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #Search {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-radius 0.3s ease;
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 1% 0;
    padding-left: 3%;
    font-size: medium;
  }

.suggestions-list {
    width: 100%; 
    position: absolute;
    border: 2px solid #ccc;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; 
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  } 

  .suggestion-item {
    padding: 1% 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
  } 

  .suggestion-item:hover {
    background-color: #f0f0f0;
  }

 .search-bar-ac.show-suggestions #Search {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  #search {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 2%;
    border-radius: 1vh;
    font-weight: 600;
  }

  /*Search by type*/
  .search-by-type {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    border-radius: 1vh;
    background-color: rgb(239, 237, 237);
    position: relative;
  }

  .search-title {
    font-size: large;
    font-weight: 700;
    color: var(--textColor);
    padding-left: 5%;
    align-self: flex-start;
    margin-top: 1%;
  }

  .scroll-type {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }

  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 1;
  }

  .scroll-button.left {
    left: 0;
    position: fixed;
  }

  .scroll-button.right {
    right: 0;
    position: fixed;
  }

  .car-type {
    width: 100%;
    padding: 0.5vh 2vh;
    margin: 1% 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .car {
    width: 150%;
  }

  #camioneta {
    width: 125%;
  }

  #pick-up {
    width: 175%;
  }

  #camioncito {
    width: 100%;
  }

  #cabezal {
    width: 175%;
  }

  #bus {
    width: 270%;
  }

  .car-name-type {
    width: fit-content;
    margin: 0;
    font-weight: 700;
    text-decoration: underline;
    font-size: small;
    color: var(--textColor);
  }

  /*Offers*/
  .offers {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    background-color: rgb(239, 237, 237);
  }

  .buttons {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    padding: 2% 3%;
    width: 75%;
  }

  .btnCar {
    margin: 1%;
    width: auto;
    color: white;
    border-radius: 1vh;
    padding: 2%;
    font-size: small;
    font-weight: 500;
  }

  #recent-cars {
    background-color: #6f737b;
  }

  #offer-cars {
    background-color: var(--bgColorRed);
  }

  /*Cars updated*/

  .update-cars {
    display: flex;
    overflow-x: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5%;
  }

  .car-updated-1 {
    background-image: url("../../public/images/hondu-car-blue.png");
    background-size: cover;
    width: 40%;
    height: 100%;
    padding: 10% 0 5% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .car-updated-2 {
    background-image: url("../../public/images/hondu-car-red.png");
    background-size: cover;
    width: 40%;
    height: 100%;
    padding: 10% 0 5% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .info-car-1 {
    background-color: rgb(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10% 0 0 0;
    width: 100%;
  }

  p.lote {
    display: none;
  }

  .info-part {
    display: grid;
    align-items: start;
    justify-items: start;
    color: #425860;
    margin-left: 3%;
    font-size: xx-small;
  }

  .price {
    margin: 0;
  }

  .car-model {
    margin: 0;
  }

  .offer-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
  }

  .new-car {
    width: 75%;
  }

  /*Logos and ads*/

  .ads-logos {
    display: flex;
    justify-content: space-between;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ads-container {
    width: 90%;
    height: 40%;
    margin: 0 2% 2% 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logos-container{
    height: 100%;
  }

  .ads-container-1 {
    width: 90%;
    height: 40%;
    margin: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ad-1 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
    display: none;
  }

  .ad-15 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
  }

  .ad-3 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
  }

  .ad-2 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-top: 3%;
    border-radius: 1vh;
    display: none;
  }

  .footer {
    display: none;
  }
}



@media (max-width: 600px) {
  /*HOME STYLE*/

  .div-home{
    padding-top: 55%;
  }

  .btn-home-style {
    color: white;
    background-color: black;
    padding: 0.5% 2%;
    font-size: normal;
    font-weight: 600;
    transition: all 0.3s ease; 
    display: flex;
    padding: 1% 2%;
    align-items: center;
    justify-content: center;
    width: 60vw;
    margin: 2% 0;
    border-radius: 1vh;
 }

  .btn-home-style:hover {
    color: black;
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .home-btn{
    border-color: var(--bgColorRed);
    margin: 2% 1% 0% 1%;
    font-size: large;
    padding: 1% 2%;
    border-radius: 1vh;
    font-weight: 600;
    position: relative;
    background: none;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
  }

  .home-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/images/button-back.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }

  .home-btn:hover::before {
    opacity: 1;
  }

  .ref-home{
    color: black;
  }
  
  .home-btn:hover,
  .home-btn:hover .ref-home {
    color: white;
  }

  .home-btn:hover {
    color: white;
  }

    /*search-bar*/
    .search-bar {
      display: flex;
      padding-top: 50%;
      margin: 0 5%;
      align-items: center;
    }

  /*search-bar*/
  .search-bar-home {
    display: flex;
    padding-top: 30%;
    margin: 0 5%;
    align-items: center;
    
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    display: none;
  }
  
  .left-arrow {
    left: 0;
    width: 3%;
  }
  
  .arrow-img{
  width: 100%;
  padding: 0;
  margin: 0;
  }
  
  .right-arrow {
    right: 0;
    width: 3%;
  }

  .search-bar-ac {
    position: relative;
    width: 60%;
    margin-right: 1%;
  }

  .search-ttl{
    display: none;
  }

  .search-presentation{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .search-actions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #Search {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-radius 0.3s ease;
    border-color: transparent;
    background-color: var(--bgColorDarkGrey);
    padding: 1% 0;
    padding-left: 3%;
    font-size: medium;
  }

.suggestions-list {
    width: 100%; 
    position: absolute;
    border: 2px solid #ccc;
    border-top: none;
    background-color: var(--bgColorDarkGrey);
    box-sizing: border-box;
    list-style-type: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000; 
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  } 

  .suggestion-item {
    padding: 1% 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
  } 

  .suggestion-item:hover {
    background-color: #f0f0f0;
  }

 .search-bar-ac.show-suggestions #Search {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  #search {
    border-color: transparent;
    background-color: var(--bgColorRed);
    color: white;
    font-size: x-small;
    margin-right: 0.5%;
    padding: 2%;
    border-radius: 1vh;
    font-weight: 600;
  }

  /*Search by type*/
  .search-by-type {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    border-radius: 1vh;
    background-color: rgb(239, 237, 237);
    position: relative;
  }

  .search-title {
    font-size: large;
    font-weight: 700;
    color: var(--textColor);
    padding-left: 5%;
    align-self: flex-start;
    margin-top: 1%;
  }

  .scroll-type {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }

  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 1;
  }

  .scroll-button.left {
    left: 0;
    position: fixed;
  }

  .scroll-button.right {
    right: 0;
    position: fixed;
  }

  .car-type {
    width: 100%;
    padding: 0.5vh 2vh;
    margin: 1% 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .car {
    width: 150%;
  }

  #camioneta {
    width: 125%;
  }

  #pick-up {
    width: 175%;
  }

  #camioncito {
    width: 100%;
  }

  #cabezal {
    width: 175%;
  }

  #bus {
    width: 270%;
  }

  .car-name-type {
    width: fit-content;
    margin: 0;
    font-weight: 700;
    text-decoration: underline;
    font-size: small;
    color: var(--textColor);
  }

  /*Offers*/
  .offers {
    background-image: url("../../public/images/honduautos-background.png");
    width: 90%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    background-color: rgb(239, 237, 237);
  }

  .buttons {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    padding: 2% 3%;
    width: 75%;
  }

  .btnCar {
    margin: 1%;
    width: auto;
    color: white;
    border-radius: 1vh;
    padding: 2%;
    font-size: small;
    font-weight: 500;
  }

  #recent-cars {
    background-color: #6f737b;
  }

  #offer-cars {
    background-color: var(--bgColorRed);
  }

  /*Cars updated*/

  .update-cars {
    display: flex;
    overflow-x: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5%;
  }

  .car-updated-1 {
    background-image: url("../../public/images/hondu-car-blue.png");
    background-size: cover;
    width: 40%;
    height: 100%;
    padding: 10% 0 5% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .car-updated-2 {
    background-image: url("../../public/images/hondu-car-red.png");
    background-size: cover;
    width: 40%;
    height: 100%;
    padding: 10% 0 5% 0;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .info-car-1 {
    background-color: rgb(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10% 0 0 0;
    width: 100%;
  }

  p.lote {
    display: none;
  }

  .info-part {
    display: grid;
    align-items: start;
    justify-items: start;
    color: #425860;
    margin-left: 3%;
    font-size: xx-small;
  }

  .price {
    margin: 0;
  }

  .car-model {
    margin: 0;
  }

  .offer-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
  }

  .new-car {
    width: 75%;
  }

  /*Logos and ads*/

  .ads-logos {
    display: flex;
    justify-content: space-between;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ads-container {
    width: 90%;
    height: 40%;
    margin: 0 2% 2% 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logos-container{
    height: 100%;
  }

  .ads-container-1 {
    width: 90%;
    height: 40%;
    margin: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ad-1 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
    display: none;
  }

  .ad-15 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
  }

  .ad-3 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-bottom: 3%;
    border-radius: 1vh;
  }

  .ad-2 {
    width: 80%;
    margin: 0 10%;
    height: 100%;
    margin-top: 3%;
    border-radius: 1vh;
    display: none;
  }

  .footer {
    display: none;
  }
}
