.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .cars-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  