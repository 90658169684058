

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    .main-container-car-user{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        border-radius: 10px;
    }

    .abonar-btn{
        background-color: var(--bgColorRed);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 1% 2%;
        margin: 1%;
        cursor: pointer;
    }

    .info-car-user{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .car-img-u{
        width: 20%;
    }

    .especificaciones-user{
        display: flex;
        margin:0 5%;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
        font-weight: 500;
    }

    .car-title-user{
        margin-left: 5%;
        font-weight: bold;
    }

    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: 500;
    }

    .pdf-show{
        color: black;
        display: flex;
        align-items: center;
        margin-top: 1%;
    }

    .pdf-img{
        width: 5%;
    }

    .widget-pdf{
        width: 25%;
        margin-top: 1%;
      }

    .pdf-container-cloudinary{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media(min-width:600px) and (max-width:900px){
    .main-container-car-user{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 90%;
        border-radius: 10px;
    }

    .abonar-btn{
        background-color: var(--bgColorRed);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 1% 2%;
        margin: 1%;
        cursor: pointer;
    }

    .info-car-user{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .info-car-user *{
        padding: 0;
        margin: 0;
    }

    .car-img-u{
        width: 100%;
    }

    .especificaciones-user{
        display: flex;
        flex-direction: column;
        margin:0 5%;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
        font-weight: 500;
    }

    .head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .car-title-user{
        margin-left: 5%;
        font-weight: bold;
    }

    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: 500;
    }

    .pdf-show{
        color: black;
        display: flex;
        align-items: center;
        margin-top: 1%;
    }

    .pdf-img{
        width: 5%;
    }

    .widget-pdf{
        width: 25%;
        margin-top: 1%;
      }

    .pdf-container-cloudinary{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

@media(max-width:600px){
    .main-container-car-user{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 90%;
        border-radius: 10px;
    }

    .abonar-btn{
        background-color: var(--bgColorRed);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 1% 2%;
        margin: 1%;
        cursor: pointer;
    }

    .info-car-user{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .info-car-user *{
        padding: 0;
        margin: 0;
    }

    .car-img-u{
        width: 100%;
    }

    .especificaciones-user{
        display: flex;
        flex-direction: column;
        margin:0 5%;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
        font-weight: 500;
    }

    .head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .car-title-user{
        margin-left: 5%;
        font-weight: bold;
    }

    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: 500;
    }

    .pdf-show{
        color: black;
        display: flex;
        align-items: center;
        margin-top: 1%;
    }

    .pdf-img{
        width: 5%;
    }

    .widget-pdf{
        width: 25%;
        margin-top: 1%;
      }

    .pdf-container-cloudinary{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

