

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

img{
    margin: 0;
    padding: 0;
}

button{
    border-color: transparent;
}

h1{
    margin: 0;
    padding: 0;
}

ul{
    margin: 0;
    padding: 0;
    list-style-type: disc;
}

.file-input-label {
    display: inline-block;
    padding: 25% 50%;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: small;
    display: flex;
    color: black;
    width: 100%;
    background-color: var(--bgColorGrey);
  }
  
  .file-input-label:hover {
    background-color: var(--bgColorDarkGrey);
  }

.p-upload{
    font-size: small;
    font-weight: 500;
}

  .preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .preview img {
    max-width: 150px; 
    max-height: 150px;
    margin: 5px;
  }
