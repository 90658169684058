

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
}



.fixed-nav{
    position: fixed;
    width: 100%;
    z-index: 1000;
}

A{
  margin: 0;
  padding: 0;
}

.logo-nav{
  width: 5%;
  position: fixed;
  right: 0;
}

@media (min-width:900px){
    /*style for red nav part*/
.drop-movil{
  display: none;
}
.texto-nuevo{
  display: none
}

.tomain{
  display: flex;
  width: fit-content;
} 

.phone-logos-container{
  width: 100%;
  display: flex;
}

.nav-logos-side{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.nav-logos-side a{
  width: 100%;
}

.nav-logos-side img {
  width: 1%;
}

#insta-nav{
  width: 1vw;
}

.column-1{
    height: 100%;
    width: 100%;
    background-color: var(--bgColorRed);
}

.column-2{
  height: 100%;
  border-bottom-left-radius: 3vh;
  background-color: var(--bgColorRed);
}

.red-part-2{
    background-color: var(--bgColorRed);
    display: flex;
    flex-direction: row-reverse;
    padding:1%;
    align-items: center;
    position: relative;
    z-index: -1;   
}


.red-part-2-admin{
  background-color: var(--bgColorRed);
  display: flex;
  flex-direction: row-reverse;
  padding:1%;
  align-items: center;
  position: relative;
  z-index: -1;   
}

.admin-ref{
  display: flex;
  font-size: 0.9em;
  align-items: center;
  margin: 0 1%;
  color: white;
}

.red-part{
    background-color: var(--bgColorGrey);
    display: grid;
    grid-template-columns: 0.5fr 0.45fr 1fr;
    align-items: center;
    position: relative;
    z-index: -1;   
}

.red-part-2 a{
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    margin: 0;
    width: 50%;
    display: flex;
    font-size: small;
    align-items: center;
    justify-content: center;
    padding: 0;
}

p{
    margin: 0;
    padding: 0;
}

.icon-nav{
    height: 3.5vh;
}

.icon-nav#insta{
    height: 3vh;
    padding-right: 1%;
}

/*style for grey nav part*/

.grey-part{
    background-color: var(--bgColorGrey);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1% 0;
}

.loading .logo-honduautos {
  transform: translateY(-20px);
}

.logo-honduautos{
    height: 10%;
    transition: transform 0.3s ease;
    width: 25.9%;    
}

.logo-grid{
    display: inherit;
}

.nav-link{
    color: black;
    font-weight: 600;
    font-size: medium;
    transition: background-color 0.3s ease;
}

.nav-link.active {
  background-color: var(--bgColorRed);
  color: #fff;
  border-radius: 1vw;
}

.menu-grid{
    justify-content: center;
    align-items: center;
    display: flex;
}

.menu-grid-blind a{
    color: var(--bgColorGrey);
}

.grey-part p{
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
}

.grey-part a{
    text-decoration: none;
    padding: 1% 3%;
}

.grey-part img{
    z-index: 1;
    position: fixed;
    top: 1%;
    background-color: var(--bgColorGrey);
    border-radius: 0 2.5vh 0 0;
    border-color: transparent;
    left: 0;
    margin: 0.5% 0 0 0;
    padding: 0.2%;
}
}

@media (min-width: 600px) and (max-width: 900px){
  /* Estilos para la parte roja */

  .column-1 {
    display: none;
  }

  .column-2 {
    display: none;
  }

  .texto-nuevo{
    color: white;
    font-size: 20px; 
  }

  .red-part-2 {
    background-color: var(--bgColorRed);
    display: flex;
    flex-direction: column;
    padding: 2%;
    align-items: center;
    position: relative;
    z-index: -1;
  }
  
  .admin-ref{
    color: var(--bgColorRed);
  }

  .red-part {
    background-color: var(--bgColorRed);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: -1;
  }

  .red-part-2 a {
    display: none;
  }

  .icon-nav {
    display: none;
  }

  .menu-grid-blind a{
      display: none;
  }


  /* Estilos para la parte gris */
  .grey-part {
    background-color: var(--bgColorGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }

  .logo-honduautos {
    height: 7vh;
    width: auto;
  }

  #phone{
      display: none;
  }

  .menu-grid {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .nav-link {
    color: black;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    margin: 0 2vw;
    width: 10vh;
    background-color: #E8E7E7;
    border-radius: 5vh;
    text-align: center;
    font-size: small;
  }

  .grey-part p {
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    text-align: center;
  }

  .grey-part a {
    text-decoration: none;
    padding: 5px 0;
  }

  .grey-part img {
    z-index: 1;
    position: relative;
    background-color: var(--bgColorGrey);
    border-radius: 0;
    margin: 10px 0;
    padding: 0;
  }
}


@media (max-width: 600px) {
    /* Estilos para la parte roja */

    .column-1 {
      display: none;
    }
  
    .column-2 {
      display: none;
    }

    .red-part {
      background-color: var(--bgColorRed);
      display: flex;
      flex-direction:column-reverse;
      align-items: center;
      justify-content: center; 
      position: relative;
      z-index: -1;
    }

    .texto-nuevo{
      position: relative;
      color: white;
      font-size: 13px; 
      text-align: center;
    }
  
    .red-part-2 {
      background-color: var(--bgColorRed);
      display: flex;
      flex-direction: column;
      padding: 5%;
      align-items: center;
      position: relative;
      z-index: -1;
    }
    
    .admin-ref{
      color: var(--bgColorRed);
    } 
  
    .red-part-2 a {
      display: none;
    }
  
    .icon-nav {
      display: none;
    }

    .menu-grid-blind a{
        display: none;
    }
  
  
    /* Estilos para la parte gris */
    .grey-part {
      background-color: var(--bgColorGrey);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
    }
  
    .logo-honduautos {
      height: 7vh;
      width: auto;
    }

    #phone{
        display: none;
    }

    .menu-grid {
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
  
    .nav-link {
      color: black;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      margin: 0 2vw;
      width: 10vh;
      background-color: #E8E7E7;
      border-radius: 5vh;
      text-align: center;
      font-size: small;
    }
  
    .grey-part p {
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 600;
      text-align: center;
    }
  
    .grey-part a {
      text-decoration: none;
      padding: 5px 0;
    }
  
    .grey-part img {
      z-index: 1;
      position: relative;
      background-color: var(--bgColorGrey);
      border-radius: 0;
      margin: 10px 0;
      padding: 0;
    }
  }
  