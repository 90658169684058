

:root{
    --bgColorRed: #DB3A34;
    --bgColorGrey: #F7F7F7;
    --bgColorYellow: #FFD68F;
    --bgColorDarkGrey: #D9D9D9;
    --bgColorDarkbtn: #6D7F86;
    --textColor: #425860;
}



p{
    padding: 0.5vh 0;
    margin: 0;
}

@media(min-width:900px){
    .main-container-car-b{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        border-radius: 10px;
    }

    #dollar{
        width: 35px;
    }

    .add-buyer-button{
        width: 30%;
        padding: 0.7% 0;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        border-radius: 10px;
        margin-left: 5%;
        margin-top: 1%;
    }   

    .head{
        display: flex;
    }

    .info-car{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .car-models-m{
        width: 27%;
    }

    .car-title-m{
        display: none;
    }

    .info-info{
    width: 100%;
    }

    .car-img-b{
        width: 20%;
    }

    

    .especificaciones-b{
        display: flex;
        margin:0 5%;
        flex-direction: column;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
        font-weight: 500;
    }

    .car-title{
        margin-left: 5%;
        font-weight: bold;
    }

    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }
    .precios-b{
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: bold;
    }

    .precio-oferta{
        margin-left: 5%;
        font-size: 0.9em;
        color: #D8A226;
        font-weight: bold;
    }

    .edit-btns{
        display: flex;
        flex-direction: column;
        width: 7%;
    }

    .icon{
        border-color: transparent;
        background-color: transparent;
        display: flex;
        justify-content: center;
    }

    #dollar{
        width: 45px;
    }

    .checkbox-b{
        margin-right: 15%;
    }
}

@media(min-width: 600px) and (max-width: 900px){
    .main-container-car{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        border-radius: 10px;
    }

    #dollar{
        width: 35px;
    }

    .add-buyer-button{
        width: 30%;
        padding: 0.7% 0;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        border-radius: 10px;
        margin-left: 5%;
        margin-top: 1%;
    }   

    .head{
        display: flex;
    }

    .info-car{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }

    .car-models-m{
        width: 50%;
    }

    .car-img{
        width: 100%;
    }

    .car-img-b{
        width: 50%;
    }

    .especificaciones{
        display: flex;
        flex-direction: column;
        margin:0 5%;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
    }

    .especificaciones p{
        padding: 0;
        margin: 0;
    }

    .car-title{
        margin-left: 5%;
        font-weight: bold;
        display: none;
    }

    .car-title-m{
        margin-left: 5%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }

    .precios{
        display: flex;
        flex-direction: column;
    }

    .precios p{
        padding: 0;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: bold;
    }

    .precio-oferta{
        margin-left: 5%;
        font-size: 0.9em;
        color: #D8A226;
        font-weight: bold;
    }

    .edit-btns{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .icon{
        border-color: transparent;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #cancel{
        width: 30px;
    }

    #edit{
        width: 30px;
    }

    #dollar{
        width: 45px;
    }
}

@media(max-width:600px){
    .main-container-car{
        background-color: #F1F1F1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        border-radius: 10px;
    }

    #dollar{
        width: 35px;
    }

    .add-buyer-button{
        width: 30%;
        padding: 0.7% 0;
        background-color: var(--bgColorRed);
        color: white;
        font-weight: 600;
        border-radius: 10px;
        margin-left: 5%;
        margin-top: 1%;
    }   

    .head{
        display: flex;
    }

    .info-car{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }

    .car-models-m{
        width: 50%;
    }

    .car-img{
        width: 100%;
    }

    .car-img-b{
        width: 50%;
    }

    .especificaciones{
        display: flex;
        flex-direction: column;
        margin:0 5%;
        font-size: medium;
        font-size: 0.9em;
        justify-content: space-between;
    }

    .especificaciones p{
        padding: 0;
        margin: 0;
    }

    .car-title{
        margin-left: 5%;
        font-weight: bold;
        display: none;
    }

    .car-title-m{
        margin-left: 5%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .car-lote{
        margin-left: 5%;
        font-size: 0.9em;
        font-weight: 500;
    }

    .precios{
        display: flex;
        flex-direction: column;
    }

    .precios p{
        padding: 0;
    }

    .precio{
        margin-left: 5%;
        font-size: 0.9em;
        color: var(--bgColorRed);
        font-weight: bold;
    }

    .precio-oferta{
        margin-left: 5%;
        font-size: 0.9em;
        color: #D8A226;
        font-weight: bold;
    }

    .edit-btns{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .icon{
        border-color: transparent;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #cancel{
        width: 30px;
    }

    #edit{
        width: 30px;
    }

    #dollar{
        width: 45px;
    }
}