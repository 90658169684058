:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
}

p {
  padding: 0.5vh 0;
  margin: 0;
}

img {
  margin: 0;
  padding: 0;
}

button {
  border-color: transparent;
}

h1 {
  margin: 0;
  padding: 0;
}

input {
  margin: 0;
  padding: 0;
}

/*Filter-menu*/

.loading-overlay-stock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.no-cars-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  font-size: 18px;
  color: #555;
}

.filter-header {
  display: flex;
  
  align-items: center;
  justify-content: center; /* Alinea el título y el botón en extremos opuestos */
  padding: 0 10px; /* Opcional: ajusta el padding según tus necesidades */
}

.filter-title {
  margin: 0;
}

.reset-filters-button {
  background-color: var(--bgColorRed);
  border: none;
  color: white; /* Ajusta el color según tu tema */
  cursor: pointer;
  font-size: small;
  padding: 5px 10px; /* Ajusta el padding según tus necesidades */
  border-radius: 1vh;
  margin-left: 10%;
}

.reset-filters-button:hover {
  text-decoration: none;
}


@media (min-width: 900px) {
  .option-title {
    background-color: #f5f4f4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    margin-left: 2%;
    padding: 1%;
    cursor: pointer;
  }

  .btn-toggle-filters {
    display: none;
  }

  .drop-menu {
    width: 100%;
    display: flex;
    background-color: white;
    margin-left: 4%;
    flex-direction: column;
    cursor: pointer;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .check {
    margin-right: 3%;
  }

  .filterbox {
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0%;
    margin-top: 5%;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 1%;
    height: 60vh;
  }

  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }

  .stock-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .column-stock {
    display: flex;
    flex-direction: column;
  }

  .column-s {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .btn-filter {
    margin-top: 5%;
    padding: 5% 0;
    background-color: var(--bgColorRed);
    color: white;
    border-radius: 10px;
    font-weight: 600;
  }

  .span-filter {
    width: 17vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1% 0%;
    padding-left: 6%;
    padding: 5% 0 5% 6%;
  }

  .menu-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .type-filter-bus {
    width: 3.2vw;
    margin-left: 35%;
  }

  .type-filter-sedan {
    width: 4vw;
    margin-left: 20%;
  }

  .type-filter-camioneta {
    width: 4vw;
    margin-left: 8%;
  }

  .type-filter-pickup {
    width: 4vw;
    margin-left: 20%;
  }

  .type-filter-camion {
    width: 3vw;
    margin-left: 20%;
  }

  .type-filter-camioncito {
    width: 3.3vw;
    margin-left: 5%;
  }

  .type-filter-cabezal {
    width: 3.5vw;
    margin-left: 20%;
  }

  .carbox {
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 3%;
    overflow-y: auto;
    max-height: 90vh;
    margin-left: 3%;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
  }

  #arrow {
    margin: 0;
    width: 40%;
  }

  .option-title .arrows {
    display: inline-block;
    margin-left: auto;
  }

  .arrows {
    margin: 0;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-title.first {
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
  }

  .option-title.last {
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .option-title {
    background-color: #f5f4f4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    margin-left: 2%;
    padding: 1%;
    cursor: pointer;
  }

  .btn-toggle-filters {
    width: 100%;
  }

  .drop-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-left: 2%;
    flex-direction: column;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .check {
    margin-right: 3%;
  }

  .filterbox {
    width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0%;
    margin-top: 5%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1%;
    height: 65vh;
  }

  .stock-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .column-stock {
    display: flex;
    flex-direction: column;
  }

  .column-s {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .btn-filter {
    margin-top: 5%;
    padding: 5% 0;
    background-color: var(--bgColorRed);
    color: white;
    border-radius: 10px;
    font-weight: 600;
  }

  .span-filter {
    width: 100%;
    margin: 1% 0%;
    padding-left: 6%;
    display: flex;
    align-items: center;
  }

  .menu-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 73vw;
  }

  .type-filter-bus {
    width: 15vw;
    margin-left: 35%;
  }

  .type-filter-sedan {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camioneta {
    width: 15vw;
    margin-left: 8%;
  }

  .type-filter-pickup {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camion {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camioncito {
    width: 15vw;
    margin-left: 5%;
  }

  .type-filter-cabezal {
    width: 15vw;
    margin-left: 20%;
  }

  .carbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 3%;
    overflow-y: auto;
    max-height: 90vh;
    margin-left: 3%;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
  }

  .filterbox {
    width: 80%;
  }

  #arrow {
    margin: 0;
    width: 40%;
  }

  .option-title .arrows {
    display: inline-block;
    margin-left: auto;
  }

  .arrows {
    margin: 0;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-title.first {
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
  }

  .option-title.last {
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
  }
}

@media (max-width: 600px) {
  .btn-toggle-filters {
    background-color: var(--bgColorRed);
    color: white;
    font-weight: bold;
    padding: 1% 0;
  }

  .option-title {
    background-color: #f5f4f4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    margin-left: 2%;
    padding: 1%;
    cursor: pointer;
  }

  .btn-toggle-filters {
    width: 100%;
  }

  .drop-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-left: 2%;
    flex-direction: column;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .check {
    margin-right: 3%;
  }

  .filterbox {
    width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0%;
    margin-top: 5%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1%;
    height: 50vh;
  }

  .stock-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .column-stock {
    display: flex;
    flex-direction: column;
  }

  .column-s {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .btn-filter {
    margin-top: 5%;
    padding: 2% 3%;
    background-color: var(--bgColorRed);
    color: white;
    border-radius: 10px;
    font-weight: 600;
  }

  .span-filter {
    width: 100%;
    margin: 1% 0%;
    padding-left: 6%;
    display: flex;
    align-items: center;
  }

  .menu-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 73vw;
  }

  .type-filter-bus {
    width: 15vw;
    margin-left: 35%;
  }

  .type-filter-sedan {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camioneta {
    width: 15vw;
    margin-left: 8%;
  }

  .type-filter-pickup {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camion {
    width: 15vw;
    margin-left: 20%;
  }

  .type-filter-camioncito {
    width: 15vw;
    margin-left: 5%;
  }

  .type-filter-cabezal {
    width: 15vw;
    margin-left: 20%;
  }

  .carbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 3%;
    overflow-y: auto;
    max-height: 90vh;
    margin-left: 3%;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
  }

  .filterbox {
    width: 80%;
  }

  #arrow {
    margin: 0;
    width: 40%;
  }

  .option-title .arrows {
    display: inline-block;
    margin-left: auto;
  }

  .arrows {
    margin: 0;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-title.first {
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
  }

  .option-title.last {
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
  }
}
