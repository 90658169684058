

:root {
  --bgColorRed: #db3a34;
  --bgColorGrey: #f7f7f7;
  --bgColorYellow: #ffd68f;
  --bgColorDarkGrey: #d9d9d9;
  --bgColorDarkbtn: #6d7f86;
  --textColor: #425860;
}



p {
  padding: 0.5vh 0;
  margin: 0;
}

@media (min-width: 900px) {
  .main-container-compradores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .comprador-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    margin-left: 5%;
  }

  .comprador-correo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    margin: 0 1%;
    width: 20vw;
  }

  .comprador-carros {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0 5% 0% 30%;
    width: 2vw;
  }

  .comprador-saldo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    margin: 0 5%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .main-container-compradores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
    align-items: center;
  }

  .comprador-name {
    font-weight: 500;
    margin: 0 1%;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: auto;
  }

  .comprador-correo {
    font-weight: 500;
    margin: 0 1%;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: auto;
  }

  .comprador-carros {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0 5% 0% 30%;
    width: 2vw;
  }

  .comprador-saldo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    margin: 0 5%;
  }
}


@media (max-width: 600px) {
  .main-container-compradores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
    align-items: center;
  }

  .comprador-name {
    font-weight: 500;
    margin: 0 1%;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: auto;
  }

  .comprador-correo {
    font-weight: 500;
    margin: 0 1%;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: auto;
  }

  .comprador-carros {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0 5% 0% 30%;
    width: 2vw;
  }

  .comprador-saldo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    margin: 0 5%;
  }
}
